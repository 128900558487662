import { useState } from "react";
import { Link } from "react-router-dom";

import Item from "./Item";
import { totalPerCurrency, hasItems, formatMoney } from "utils";
import Button from "components/Field/Button";
import DownloadIcon from "@material-ui/icons/GetApp";

// image
import { ReactComponent as ShippingIcon } from "images/local_shipping_black_24dp.svg";
import { ReactComponent as ShoppingIcon } from "images/shopping_bag_black_24dp.svg";

import { STORE_BUCKET } from "config";
import { getReadableDate } from "utils";

const Processed = ({ order, isLoading, downloadInvoice }) => {
  const orderTotals = hasItems(order)
    ? totalPerCurrency(order.items)?.[0]
    : null;
  const itemCount = hasItems(order) ? order.items.length : 0;
  const orderImages = hasItems(order)
    ? order.items.map((item) => `${STORE_BUCKET}/${item.image}`).splice(0, 3)
    : [];
  const [showDetails, setShowDetails] = useState(false);

  const statusClass = (orderStatus) => {
    const status = orderStatus.toLowerCase();

    if (status === "cancelled" || status === "declined") return "text-fatal";

    if (status === "to pay") return "text-grey-light";

    return "text-green-main";
  };

  const toggleDetails = (e) => {
    const elY = e.clientY;
    const winY = window.scrollY;
    const top = showDetails ? winY - elY : winY;
    window.scrollTo({
      top,
      left: 0,
      behavior: "smooth",
    });
    setShowDetails(!showDetails);
  };

  return (
    <section
      className={`bg-white border border-grey-light-4 text-blue-dark p-6 md:p-8 lg:w-[960px] w-full mx-auto text-left ${
        isLoading ? "opacity-20 pointer-events-none" : ""
      }`}
    >
      <div className="flex justify-between items-start">
        <div>
          <p className="grid md:block">
            <strong className="uppercase">Order: </strong>{" "}
            <span>{order.id}</span>
          </p>
          <p className="text-xs text-grey-light">
            {getReadableDate(order?.orderDate)}
          </p>
        </div>
        <p
          className={`uppercase text-sm font-bold ${statusClass(order.status)}`}
        >
          {order.status}
        </p>
      </div>

      <div
        className={`order-bag grid grid-cols-1 sm:grid-cols-2 gap-4 lg:flex items-center justify-between text-sm text-grey-light font-bold overflow-hidden duration-500 ${
          showDetails ? "max-h-0 opacity-0" : "max-h-1000 opacity-100"
        }`}
      >
        <div className="sm:flex items-center sm:space-x-6 grid grid-cols-2 gap-4 w-full pt-6 ">
          <ShoppingIcon className="w-12 h-12 opacity-50 flex-shrink-0" />

          <div className="images h-12 relative animate-fade-in">
            {orderImages.length > 0 &&
              orderImages.map((img, i) => (
                <div
                  className={`w-12 h-12 overflow-hidden rounded border border-grey-light ${
                    i === orderImages.length - 1
                      ? "opacity-100 relative shadow-lg"
                      : "opacity-20 absolute"
                  }`}
                  key={`img-${i}`}
                  style={{ left: `${i * 12}px` }}
                >
                  <img
                    src={img}
                    alt={img}
                    className="object-cover object-top h-full"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 md:flex flex-grow lg:justify-evenly justify-end w-full">
          <p>{itemCount} product(s) in order</p>
          <p className="uppercase">
            {orderTotals.currency} {orderTotals.currencySymbol}
            {formatMoney(orderTotals.total)}
          </p>
        </div>

        <div className="links flex space-x-2 sm:col-span-full md:justify-end justify-start pt-4 sm:pt-0 flex-shrink-0">
          <Link to={`/store/order-status/${order.id}`}>
            <Button
              text="Track Order"
              className="w-auto inline-block place-self-end rounded"
              variation="outline"
              size="xs"
              fontWeight="normal"
              color="grey-light"
            />
          </Link>
          <Button
            text="View Details"
            className="w-auto inline-block place-self-end rounded"
            variation="outline"
            size="xs"
            fontWeight="normal"
            callback={toggleDetails}
          />
          {order.hasInvoice && (
            <Button
              className="w-auto inline-block place-self-end rounded h-[28px]"
              variation="outline"
              size="xs"
              fontWeight="normal"
              callback={() => downloadInvoice(order.id)}
            >
              <p className="flex flex-nowrap items-cente space-x-1">
                <span className="hidden md:block">Invoice</span>
                <DownloadIcon style={{ fontSize: 14 }} />
              </p>
            </Button>
          )}
        </div>
      </div>

      <div
        className={`grid gap-6 overflow-hidden duration-500 ${
          showDetails ? "max-h-1000 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className="flex justify-between items-start pt-6 space-x-5">
          <div>
            <strong className="block text-blue-dark text-xs">Status:</strong>
            <p className="text-sm text-green-main">
              Your order &amp; payment were successfully processed.
            </p>
          </div>
          <div className="flex space-x-2">
            <Link to={`/store/order-status/${order.id}`}>
              <Button
                text="Track Order"
                className="w-auto inline-block rounded sm:text-xs sm:font-normal flex-shrink-0"
                variation="outline"
                size="xs"
                fontWeight="normal"
                color="grey-light"
              />
            </Link>
            {order.hasInvoice && (
              <Button
                className="w-auto inline-block place-self-end rounded h-[28px]"
                variation="outline"
                size="xs"
                fontWeight="normal"
                callback={() => downloadInvoice(order.id)}
              >
                <p className="flex flex-nowrap items-cente space-x-1">
                  <span className="hidden md:block">Invoice</span>
                  <DownloadIcon style={{ fontSize: 14 }} />
                </p>
              </Button>
            )}
          </div>
        </div>

        <div className="flex items-start space-x-6 text-grey-light text-xs">
          <ShippingIcon className="flex-shrink-0 w-12 h-12 opacity-50" />
          <ul className="flex-grow grid gap-2 mb-4">
            {order?.orderDate && (
              <>
                <li>Placed on {getReadableDate(order?.orderDate)}</li>
                <li>Paid on {getReadableDate(order?.orderDate)}</li>
              </>
            )}
            {order?.deliveryDate && (
              <li>Delivered on {getReadableDate(order?.deliveryDate)}</li>
            )}
          </ul>
        </div>

        <hr className="border-grey-light-4" />
        {order &&
          order.items.map((item) => (
            <Item item={item} Processed={true} key={item.productId} />
          ))}
        <hr className="border-grey-light-4" />

        <div className="totals w-full lg:w-480 place-self-end grid gap-3">

          <div className="flex items-start justify-end space-x-4 text-base xl:text-lg pt-2">
            <p>Total:</p>
            <ul className="font-bold">
              <li className="text-right mb-2 uppercase">
                <strong>
                  {orderTotals.currency} {orderTotals.currencySymbol}
                  {formatMoney(orderTotals.total)}
                </strong>
              </li>
            </ul>
          </div>
        </div>

        <Button
          text="Hide Details"
          className="w-auto inline-block place-self-end rounded sm:text-xs sm:font-normal"
          variation="outline"
          size="xs"
          fontWeight="normal"
          callback={toggleDetails}
        />
      </div>
    </section>
  );
};

export default Processed;
