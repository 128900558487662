import { useEffect, useState } from "react";
const Header = () => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // iframe event
    const origin = window.location.origin;
    const allowed = [origin, process.env.REACT_APP_UBX_URL];
    const windowMessage = function (event) {
    
      if (!allowed.includes(event.origin)) {
        return;
      }

      if (typeof event.data == "object") {
        return;
      }

      try {
        const dataObj = JSON.parse(event.data);

        if (dataObj.fullscreen)
          return setIsActive(true);

        setIsActive(false)
      } catch (error) {
        setIsActive(false);
      }
    };

    window.addEventListener("message", windowMessage);

    return () => window.removeEventListener("message", windowMessage);
  }, []);

  const iframeContainerClass = isActive
    ? "h-screen fixed top-0 left-0 lg:h-24 lg:relative"
    : "h-24 relative";

  return (
    <div
      id="header-iframe-container"
      className={`shadow-md w-full bg-white overflow-hidden z-999 ${iframeContainerClass}`}
    >
      <iframe
        id="header-iframe"
        src={`${process.env.REACT_APP_UBX_URL}/header-export`}
        title="header"
        scrolling="no"
        frameBorder="0"
        className="w-full z-50 bg-white relative h-screen"
      ></iframe>
    </div>
  );
};

export default Header;
