import { useState } from "react";
import OtpInput from "react-otp-input";
import Button from "components/Field/Button";
import { isFunction } from "lodash";

const OTPModal = ({ closeCallback, callBack, resendCallback }) => {
  const handleChange = (val) => {
    setOtp(val);
  };

  const [otp, setOtp] = useState("");

  const handleClose = () => {
    if (isFunction(closeCallback)) closeCallback();
  };

  const handleSubmit = () => {
    const isValid = otp.length === 4;
    setError(!isValid);
    if (isFunction(callBack) && isValid) callBack(otp);
  };

  const handleResend = () => {
    if (isFunction(resendCallback)) resendCallback();
  };

  const [error, setError] = useState(false);

  return (
    <div
      className={`fixed left-0 top-0 w-screen h-screen z-500 grid place-content-center p-4 animate-fade-in`}
    >
      <div
        className="back-drop bg-black bg-opacity-70 absolute left-0 top-0 w-full h-full z-500"
        onClick={handleClose}
      ></div>

      <div className="p-6 md:p-8 bg-white rounded-xl grid gap-4 font-base text-blue-dark place-content-center relative z-999 w-full sm:w-480">
        <h6 className="text-xl font-bold">
          We sent you OTP to your phone number
        </h6>
        <p className="mb-4">
          We have sent a OTP (One Time Password) to verify that it's you, to the
          mobile number +63 9444 444444 that you provided.
        </p>
        <div className="flex items-center justify-center">
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            containerStyle="grid gap-2.5 grid-cols-4 w-56"
            inputStyle={{
              width: "100%",
              height: "3rem",
              border: `1px solid ${error ? "#BD2B2B" : "#CBD5E1"}`,
            }}
            focusStyle={{
              border: `1px solid ${error ? "#BD2B2B" : "#73B944"}`,
              outline: "none",
            }}
          />
        </div>
        <p className="text-center mb-4">
          Didn't received a code?{" "}
          <button
            type="button"
            className="text-green-main underline"
            onClick={handleResend}
          >
            Resend
          </button>
        </p>
        <Button text="Submit" onClick={handleSubmit} />
        <button
          className="font-bold text-grey-light bg-none uppercase"
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default OTPModal;
