import { Link } from "react-router-dom";
import moment from "moment";
import Button from "./Field/Button";
import useCart from "hooks/useCart";

const TravelHistory = ({
  className,
  link = "/store/order-status",
  trackings,
}) => {
  const { isSignIn } = useCart();

  return (
    <div
      className={`text-center py-4 lg:py-8 px-4 lg:px-6 grid gap-2 ${
        className || ""
      }`}
    >
      <h6 className="uppercase font-bold text-blue-dark">Travel History</h6>
      {trackings && trackings.length > 0 ? (
        <div className="px-6 pt-8 bg-grey-light-4 bg-opacity-20 mt-4 mb-8">
          {trackings.map(({ trackingData: { checkpoints }, id }, index) => (
            <ul className="pb-4" key={`trackingData-${id}`}>
              <li className="flex gap-4 items-center mb-8">
                <span
                  className="block h-px
                 flex-grow bg-grey-light-4"
                />
                {trackings.length > 1 && (
                  <p className="font-bold text-lg">Package #{index + 1}</p>
                )}
                <span
                  className="block h-px
                 flex-grow bg-grey-light-4"
                />
              </li>
              {checkpoints && checkpoints.length > 0 ? (
                <li className="relative ml-24 text-sm">
                  <ol className={`text-left text-grey-light mt-4 mb-8`}>
                    {checkpoints
                      .reverse()
                      .map(
                        (
                          {
                            message,
                            subtag_message,
                            checkpoint_time,
                            location,
                          },
                          index
                        ) => (
                          <li
                            className={`pl-4 relative border-grey-light-4 ${
                              checkpoints.length !== index + 1
                                ? "pb-10 border-l"
                                : ""
                            }`}
                            key={`checkpoint-${index}`}
                          >
                            <time
                              dateTime={checkpoint_time}
                              className="absolute right-full text-right mr-4 -top-1 text-xs"
                            >
                              <strong className="block whitespace-nowrap">
                                {moment(checkpoint_time).format("MMM DD YY")}
                              </strong>
                              {moment(checkpoint_time).format("HH:mm")}
                            </time>
                            <div className="relative -top-2">
                              <strong className="block text-sm leading-6 text-blue-dark">
                                {subtag_message}
                              </strong>
                              <p>{message}</p>
                              {location && <p>[{location}]</p>}
                            </div>
                            <span className="w-2 h-2 bg-grey-light-4 rounded-full block absolute -left-1 top-0"></span>
                          </li>
                        )
                      )}
                  </ol>
                </li>
              ) : (
                <li className="text-grey-light mb-8 text-sm">
                  Tracking details not available yet.
                </li>
              )}
            </ul>
          ))}
        </div>
      ) : (
        <p className="mb-8 text-grey-light">
          Tracking details will be available once the package arrives at the
          carrier. <br />
          Please check again later.
        </p>
      )}

      <div>
        <Link to={!isSignIn ? link : "/store/orders"}>
          <Button
            text={isSignIn ? "Back to Order History" : "Track another order"}
            variation="outline"
            size="sm"
            color="blue-dark"
          />
        </Link>
      </div>
    </div>
  );
};

export default TravelHistory;
