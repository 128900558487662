import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFunction } from "lodash";
import Alert from "@material-ui/lab/Alert";
import { setUserData, clearErrors } from "store/user/userSlice";
import { getIndex } from "store/helpers";
import { isEnterKey } from "utils";

// validation
import { validateField, validateAllFields } from "utils/validations";

// components
import Input from "components/Field/Input";
import Button from "components/Field/Button";
import Loading from "components/Checkout/Loading";
import Phone from "components/Field/Phone";

const Contact = ({ next, back }) => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.status);
  const loading = status === "loading";
  const exists = useSelector((state) => state.user.exists);
  const error = useSelector((state) => state.user.error);
  const userData = useSelector((state) => state.user.data);
  const { email, phone } = userData;
  const [checkedUser, setCheckedUser] = useState(false);
  const countryCode = useSelector((state) => state.user.countryCode);

  const fields = [
    {
      field: "email",
      value: email,
      error: false,
    },
    {
      field: "phone",
      value: phone,
      error: false,
    },
  ];
  const [fieldData, setFieldData] = useState(fields);

  const getErrorData = (field) => {
    const fieldIndex = getIndex(fieldData, field, "field");
    return fieldData[fieldIndex];
  };

  const onKeyPress = (e) => {
    if (loading) return;
    if (isEnterKey(e.key)) {
      handleClick();
    }
  };

  const handleClick = () => {
    setCheckedUser(false);
    const hasError = validateAllFields(
      fieldData,
      setFieldData,
      null,
      countryCode.toUpperCase()
    );

    if (!hasError) {
      dispatch(clearErrors());
      setCheckedUser(true);
    }
  };

  const goSignin = () => {
    if (isFunction(back)) back();
    dispatch(clearErrors());
  };

  useEffect(() => {
    if (isFunction(next) && !exists && checkedUser && !loading) next();
  }, [exists, next, checkedUser, loading]);

  return (
    <div className="w-full mx-auto lg:w-480 grid gap-6 pb-16">
      <h4 className="text-blue-dark uppercase text-xl xl:text-2xl text-center font-bold">
        Contact Details
      </h4>

      {!loading && exists && error && (
        <Alert severity="error">
          <h6 className="font-bold uppercase mb-2">Account already exists</h6>
          <p className="cursor-pointer" onClick={goSignin}>
            Please go to login page. <u>Click here</u>
          </p>
        </Alert>
      )}

      <div
        className={`duration-300 grid gap-6 ${
          loading ? "pointer-events-none opacity-20" : ""
        }`}
      >
        <Phone
          id="phone-contact"
          callback={(value, countryCode) => {
            dispatch(setUserData({ field: "phone", value }));
            validateField(
              "phone",
              value,
              fieldData,
              setFieldData,
              null,
              countryCode
            );
          }}
          initValue={phone}
          error={getErrorData("phone").error}
          message={getErrorData("phone").message}
          onKeyPress={onKeyPress}
        />
        <Input
          id="email"
          type="email"
          label="Email Address"
          callback={(value) => {
            dispatch(setUserData({ field: "email", value }));
            validateField("email", value, fieldData, setFieldData);
          }}
          error={getErrorData("email").error}
          message={getErrorData("email").message}
          initValue={email}
          onKeyPress={onKeyPress}
        />
      </div>

      {loading && <Loading />}

      <Button
        color="green-main"
        className="flex justify-center items-center w-full"
        onClick={handleClick}
        disabled={loading}
      >
        Continue
      </Button>
    </div>
  );
};

export default Contact;
