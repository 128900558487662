import { useRef, useState, useEffect} from "react";
import { isNull } from "lodash";

// icon
import { ReactComponent as RemoveIcon } from "images/remove_black_24dp.svg";
import { ReactComponent as AddIcon } from "images/add_black_24dp.svg";

const Quantity = ({ item, callback }) => {
  const { productId, variantId, quantity: currentQuantity, variants = [], stock: currentStock } = item;
  const { stock = currentStock || 999, minQty = 1 } = variants.filter(({id = ''}) => id === variantId)[0] || {}
  let [quantity, setQuantity] = useState(currentQuantity);
  const initial = useRef(true);
  const quantityDisable = !isNull(stock) && stock < 1

  // handle click
  const handleClick = (callback, e) => {
    e.preventDefault();
    e.stopPropagation();
    callback();
  };

  // update quantity
  const updateQuantity = (subtract = false, updatedValue) => {
    let newValue = subtract ? quantity - 1 : quantity + 1;
    const min = minQty ||  1
    const max = typeof stock === 'number' ? stock : 99

    // use value if input field was used
    if (updatedValue) newValue = updatedValue;

    // set min max limit
    if (newValue <= min) newValue = min;
    if (newValue > max) newValue = max;

    setQuantity(newValue);
  };

  // trigger update
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }
    const timer = setTimeout(() => {
      if (callback && typeof callback === "function")
        callback({ productId, variantId, quantity });
    }, 300);

    return () => clearTimeout(timer);
  }, [callback, quantity, productId, variantId]);

  useEffect(() => {
    setQuantity(currentQuantity)
  }, [currentQuantity])

  return (
    <div className="flex flex-row w-28 items-stretch justify-center overflow-hproductIdden rounded my-2">
      <button
        className="bg-grey-light-5 text-white flex flex-row flex-shrink-0 flex-grow-0 justify-center items-center p-1 w-8 disabled:cursor-not-allowed"
        onClick={handleClick.bind(null, () => updateQuantity(true))}
        disabled={quantityDisable}
      >
        <RemoveIcon className="w-4 h-4 fill-current text-white" />
      </button>
      <input
        type="number"
        name={`quantity-${productId}`}
        className="w-full text-center appearance-none remove-styling h-6 text-sm disabled:cursor-not-allowed"
        value={quantity}
        onInput={(e) => updateQuantity(false, e.currentTarget.value)}
        onFocus={(e) => e.currentTarget.select()}
        disabled={quantityDisable}
      />
      <button
        type="button"
        className="bg-grey-light-5 text-white flex flex-row flex-shrink-0 flex-grow-0 justify-center items-center p-1 w-8 disabled:cursor-not-allowed"
        onClick={handleClick.bind(null, () => updateQuantity(false))}
        disabled={quantityDisable}
      >
        <AddIcon className="w-4 h-4 fill-current text-white" />
      </button>
    </div>
  );
};

export default Quantity;
