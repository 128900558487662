import { isFunction } from "lodash";
import { getMinMaxDate } from "utils";
import { getIndex } from "store/helpers";
import { isValidPhoneNumber } from "libphonenumber-js";

export const validateEmail = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(String(value).toLowerCase());
};

export const validateMinLength = (value, length) => {
  return String(value).length >= length;
};

export const validateLength = (value, min, max) => {
  const str = String(value);
  if (str.length < min || str.length >= max) return false;
  return true;
};

export const validatePassword = (value) => {
  const patt = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/g;
  const passValid = patt.test(value);
  const lengthValid = validateLength(value, 8, 40);

  if (!lengthValid || !passValid) return false;

  return true;
};

export const validateRequired = (value) => !!value;

export const validateDate = (date) => {
  const dateLimit = getMinMaxDate();
  const currentDate = new Date(date);

  return (
    String(currentDate).toLowerCase() === "invalid date" ||
    currentDate > dateLimit.max ||
    currentDate < dateLimit.min
  );
};

export const validateField = (
  field,
  value,
  arr,
  callback,
  equal,
  countryCode,
  isRequired
) => {
  let result = false;
  let message = "";

  switch (field) {
    case "fname":
    case "lname":
      result = !validateLength(value, 2, 40);
      message = "Invalid value";
      break;

    case "address":
      result = !validateLength(value, 10, 100);
      message = "Address not found";
      break;

    case "birthday":
      result = validateDate(value);
      message = "Invalid date";
      break;

    case "agree":
      result = !validateRequired(value);
      break;

    case "password":
      result = !validateLength(value, 4, 40);
      message = "Invalid password";
      break;

    case "confirmPassword":
      result = equal !== value;
      message = "Password didn't match";
      break;

    case "email":
      result = !validateEmail(value);
      message = "Invalid Email";
      break;

    case "phone":
      result = !validatePhone(value, null, null, null, countryCode);
      message = "Invalid mobile number";
      break;

    default:
      break;
  }

  if (isRequired) {
    result = !validateRequired(value);
  }

  if (!validateRequired(value)) {
    message = "Required";
  }

  const fieldIndex = getIndex(arr, field, "field");
  const newArr = [...arr];
  newArr[fieldIndex] = { ...newArr[fieldIndex], error: result, value, message };

  if (isFunction(callback)) callback(newArr);
  return newArr[fieldIndex];
};

export const validateAllFields = (arr, callback, equal, countryCode) => {
  const newArr = arr.map((item) => {
    const { field, value, isRequired, skip } = item;

    if (skip) {
      return {
        ...item,
        error: false,
      };
    }

    return validateField(
      field,
      value,
      arr,
      callback,
      equal,
      countryCode,
      isRequired
    );
  });
  if (isFunction(callback)) callback(newArr);

  const hasError = newArr.some((item) => item.error);
  return hasError;
};

export const validatePhone = (value, country, _, _1, countryCode) => {
  const theCountry = countryCode ? countryCode : country.iso2?.toUpperCase();
  const isValid = isValidPhoneNumber(value, theCountry);
  return isValid;
};

export const isAddressField = (field) => {
  const validFields = [
    "country",
    "countryCode",
    "city",
    "street",
    "state",
    "postalcode",
    "number",
  ];

  return validFields.includes(field);
};
