import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { userCountry } from "store/user/userSlice";
import { bannersGet } from "store/bannerSlice";
import { productsGet } from "store/products/productsSlice.js";

// components
import ScrollToTop from "components/ScrollToTop";
import Header from "components/Header";
import Navigation from "components/Navigation";
import Banner from "components/Banner";
import Footer from "components/Footer";
import Cart from "components/Cart";
import CartWatcher from "components/CartWatcher";

// views
import Store from "views/Store";
import SingleProduct from "views/SingleProduct";
import OrderTracking from "views/OrderTracking";
import OrderStatus from "views/OrderStatus";
import Warranty from "views/Warranty";
import Faqs from "views/Faqs";
import TrainOnDemand from "views/TrainOnDemand";
import Checkout from "views/Checkout";
import OrderConfirmation from "views/OrderConfirmation";
import PreviousOrders from "views/PreviousOrders";
import SigninPage from "views/SigninPage";
import useCart from "hooks/useCart";

function App() {
  const dispatch = useDispatch();
  const { cartIsActive, isSignIn, getCartData } = useCart();
  const { countryCode } = useSelector((state) => state.user);

  // listen to modal status
  const hasModal = useSelector((state) => state.user.hasModal);

  useEffect(() => {
    // get user location on first load
    dispatch(userCountry());

    // get banners data
    dispatch(bannersGet());
  }, [dispatch]);

  useEffect(() => {
    if (hasModal || cartIsActive) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "";
  }, [cartIsActive, hasModal]);

  useEffect(() => {
    if (isSignIn) getCartData();
  }, [isSignIn, getCartData]);

  // fetch products if countryCode is available
  useEffect(() => {
    if(countryCode)
    dispatch(productsGet({countryCode: countryCode}));
  }, [dispatch, countryCode]);

  // initiate gtm
  useEffect(() => {
    if (process.env.ENV === "production") {
      TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER });
    }
  }, [])

  return (
    <Router>
      <div className="App flex min-h-screen flex-col">
        <Header></Header>
        <Navigation></Navigation>
        <div className="flex-grow bg-grey-light-3">
          <Cart />
          <ScrollToTop />
          <Switch>
            <Route path="/store/order-status/:orderId">
              <OrderStatus />
            </Route>
            <Route path="/store/order-status">
              <OrderTracking />
            </Route>
            <Route path="/store/warranty">
              <Warranty />
            </Route>
            <Route path="/store/faqs">
              <Faqs />
            </Route>
            <Route path="/store/train-on-demand">
              <TrainOnDemand />
            </Route>
            <Route path="/store/checkout">
              <Checkout />
            </Route>
            <Route path="/store/order-confirmation/">
              <OrderConfirmation />
            </Route>
            <Route path="/store/orders">
              <PreviousOrders />
            </Route>
            <Route path="/store/signin">
              <SigninPage />
            </Route>
            <Route path="/store/:productId">
              <SingleProduct />
            </Route>
            <Route path="/store">
              <Store />
            </Route>
            <Route path="/">
              <Store />
            </Route>
          </Switch>
        </div>
        <Banner></Banner>
        <Footer></Footer>
        <CartWatcher />
      </div>
    </Router>
  );
}

export default App;
