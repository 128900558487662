import { useRef, useState } from "react";

// image
import { ReactComponent as PlayIcon } from "images/play.svg";

const VideoEmbed = () => {
  const video = useRef();
  const play = useRef();
  const [playing, setPlaying] = useState(false);

  const handleClick = () => {
    if (!video.current || !play.current) return;

    setPlaying(!playing);

    if (playing) {
      video.current.pause();
      return;
    }
    video.current.play();
  };

  const classPlaying =
    " w-full h-full rounded-none top-0 left-0 ml-0 mt-0 opacity-0 bg-white";
  const classPaused =
    " w-12 h-12 rounded-full top-1/2 left-1/2 -ml-6 -mt-6 opacity-80 bg-green-main";

  return (
    <div className="video-wrapper w-600 max-w-full mx-auto overflow-hidden py-6 md:py-8 relative">
      <button
        type="button"
        role="none"
        className={` z-100 absolute flex items-center justify-center duration-300 ${
          !playing ? classPaused : classPlaying
        }`}
        ref={play}
        onClick={handleClick}
      >
        <PlayIcon />
      </button>
      <video
        data-poster="https://content.gymsystems.co/12rnd-website-videos/train-on-demand_explainer_banner.jpg"
        playsinline=""
        ref={video}
      >
        <source
          src="https://content.gymsystems.co/12rnd-website-videos/train-on-demand_explainer_hq.mp4"
          type="video/mp4"
          size="1080"
        />
        <source
          src="https://content.gymsystems.co/12rnd-website-videos/train-on-demand_explainer_lq.mp4"
          type="video/mp4"
          size="720"
        />
        <source
          src="https://content.gymsystems.co/12rnd-website-videos/train-on-demand_explainer_mq.mp4"
          type="video/mp4"
          size="640"
        />
      </video>
    </div>
  );
};

export default VideoEmbed;
