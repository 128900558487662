import Item from "./Item";
import TotalSection from "components/TotalSection";
import { hasItems, totalPerCurrency } from "utils";

const Display = ({ order }) => {
  const orderTotals = hasItems(order) ? totalPerCurrency(order.items) : [];

  return (
    <section className="bg-white grid gap-6 border border-grey-light-4 text-blue-dark p-6 md:p-8 lg:w-900 w-full mx-auto text-left">
      <div className="flex justify-between items-start md:items-center">
        <p className="grid md:block">
          <strong className="uppercase">Order: </strong> <span>{order.id}</span>
        </p>
        <p className="uppercase text-green-main font-bold">{order.status}</p>
      </div>
      <p className="text-green-main">
        <strong className="block text-blue-dark text-xs">Status:</strong>
        Your order &amp; payment were successfully processed.
      </p>
      <hr className="border-grey-light-4" />
      {order &&
        order.items.map((item) => (
            <Item item={item} display={true} key={item.id || item.productId} />
        ))}
      
      {orderTotals.length && (
        <TotalSection
          title="Total"
          field="total"
          orderTotals={orderTotals}
          className="text-base !opacity-100 ml-auto max-w-full min-w-[180px] space-x-4"
        />
      )}
    </section>
  );
};

export default Display;
