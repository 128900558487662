const Features = () => {
  return (
    <div className="grid md:grid-cols-3 gap-8 lg:gap-2.5 p-2.5 mb-8">
      <div className="md:p-6 text-blue-dark">
        <h6 className="text-2xl font-bold uppercase text-center">Training</h6>
        <hr className="w-12 border-green-main mx-auto my-4" />
        <ul className="list-disc ml-6 grid gap-2.5 leading-relaxed">
          <li>Choose between Body Weight and Equipment-Based Programming</li>
          <li>Designed to mimic the various fighting styles of Boxers</li>
          <li>
            Select your desired target muscle groups, available equipment,
            boxing-style and duration OR select the Workout of the Day as
            programmed by our team of sports-scientists
          </li>
        </ul>
      </div>

      <div className="md:p-6 text-blue-dark">
        <h6 className="text-2xl font-bold uppercase text-center">Nutrition</h6>
        <hr className="w-12 border-green-main mx-auto my-4" />
        <ul className="list-disc ml-6 grid gap-2.5 leading-relaxed">
          <li>
            Expertly programmed macro and micronutrient balanced Meal Plans for
            Males and Females
          </li>
          <li>
            Auto-generated with Shopping Lists that you can email or print
          </li>
          <li>
            Ability to customise your Meal Plan by swapping out meals each day
            to suit your personal requirements and preferences
          </li>
        </ul>
      </div>

      <div className="md:p-6 text-blue-dark">
        <h6 className="text-2xl font-bold uppercase text-center">
          Mindset/Recovery
        </h6>
        <hr className="w-12 border-green-main mx-auto my-4" />
        <ul className="list-disc ml-6 grid gap-2.5 leading-relaxed">
          <li>Guided meditation to assist you in calming the mind</li>
          <li>
            Coach-led mobility routines focusing on Full Body, Upper Body or
            Lower Body to help relax tight muscles and prevent soreness or risk
            of injury
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Features;
