export const getAddressValue = (addressComponent = [], field) => {
  const value = {
    long_name: "",
    short_name: "",
  };
  // ref: https://developers.google.com/maps/documentation/javascript/places-autocomplete#javascript_5
  // check address components if it has the field
  addressComponent.map(({ types = [], long_name, short_name, ...item }) => {
    if (types.includes(field)) {
      // update return value
      value.long_name = long_name;
      value.short_name = short_name;
    }
    return item
  });

  return value;
};
