import { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./style.autocomplete";
import { isFunction } from "lodash";

const AutoComplete = ({
  options,
  initValue,
  placeholder,
  callback,
  textChangeCallback,
  error,
  message,
  optionProp = "name",
  extraVal = "value",
  id = "autocomplete-field",
  label = "Autocomplete",
  ...props
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initValue || "");
  const [open, setOpen] = useState(false);

  const triggerCallback = (val, extra) => {
    // trigger callback from parent
    if (isFunction(callback)) {
      callback(val, extra);
    }
  };

  const optionExist = (options, value, optionProp) => {
    const result = options.filter((option) => option[optionProp] === value);
    return result.length > 0 ? result[0] : "";
  };

  const onChange = (_, data) => {
    // set local state
    setValue(data[optionProp]);

    triggerCallback(data[optionProp], data[extraVal]);
  };

  const triggerExtra = (options, value) => {
    const extra = optionExist(options, value, "name");
    triggerCallback(value, extra[extraVal]);
  };

  const textOnChange = (event) => {
    const { value: fieldValue } = event.target;
    setValue(fieldValue);

    if (isFunction(textChangeCallback) && fieldValue !== value)
      textChangeCallback(value);

    triggerExtra(options, value);
  };

  const textOnBlur = () => {
    triggerExtra(options, value);
  };

  return (
    <Autocomplete
      id={id}
      noOptionsText={"Country not found"}
      open={open}
      classes={{
        option: classes.option,
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      autoHighlight
      margin="normal"
      options={options}
      onChange={onChange}
      disableClearable
      getOptionLabel={(option) =>
        option[optionProp] === null || option[optionProp] === undefined
          ? ""
          : option[optionProp]
      }
      getOptionSelected={(option, value) => option[optionProp] === value}
      value={value}
      inputValue={value}
      renderOption={(option) => <>{option[optionProp]}</>}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            autoComplete="off"
            placeholder={placeholder}
            style={{ border: 0, boxShadow: "none" }}
            onChange={textOnChange}
            onBlur={textOnBlur}
            className={classes.textField}
            error={error}
            helperText={error ? message : ""}
            value={value}
            {...props}
          />
        );
      }}
    />
  );
};

export default AutoComplete;
