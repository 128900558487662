import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signIn, checkUser, registerUser, getCountry } from "API";
import { getCountryName } from "utils/countries";
import { userData } from "utils/cookies";

const clearState = {
  firstname: "",
  lastname: "",
  phone: "",
  googlePlaceID: "",
  zip: "",
  email: "",
  company: "",
  signin: false,
  address1: "",
  address2: "",
  city: "",
  state: "",
  id: null,
};

const initialState = {
  data: {
    ...clearState,
    countryCode: "",
    country: "",
    ...userData,
  },
  status: "idle",
  error: null,
  exists: false,
  edit: true,
  countryCode: "",
  latitude: 0,
  longitude: 0,
  hasModal: false,
  detectedCountryCode: "",
};

export const userSignin = createAsyncThunk(
  "user/signin",
  async ({ email, phone, password }) => {
    const response = await signIn(email, phone, password);
    return response;
  }
);

export const userCheck = createAsyncThunk(
  "user/check",
  async ({ email, phone }) => {
    const response = await checkUser({ email, phone });
    return response;
  }
);

export const userRegister = createAsyncThunk("user/register", async (user) => {
  const response = await registerUser(user);
  return response.data;
});

export const userCountry = createAsyncThunk("user/countryCode", async () => {
  const response = await getCountry();
  return response.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // eslint-disable-next-line
    setUserData: (state, { payload }) => {
      const { field, value } = payload;
      state.data[field] = value;
    },
    clearUserData: (state) => {
      state.data = clearState;
    },
    setCountry: (state, { payload }) => {
      state.countryCode = payload.toLowerCase();
      state.data.countryCode = payload;
      state.data.country = getCountryName(payload);
    },
    setDetectedCountryCode: (state, { payload }) => {
      state.detectedCountryCode = payload;
    },
    toggleEdit: (state, { payload }) => {
      state.edit = payload;
    },
    toggleModal: (state, { payload }) => {
      state.hasModal = payload;
    },
    clearErrors: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignin.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(userSignin.fulfilled, (state, action) => {
        state.status = "idle";
        const { error } = action.payload;

        if (error) {
          state.error = error;
          return;
        }

        state.data = { ...state.data, ...action.payload };
        state.data.signin = true;
      })
      .addCase(userCheck.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userCheck.fulfilled, (state, action) => {
        state.status = "idle";
        const { exists, error = null } = action.payload;
        state.exists = exists;
        state.error = error;
      })
      .addCase(userRegister.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = { ...state.data, ...action.payload };
        state.edit = false;
      })
      .addCase(userRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userCountry.fulfilled, (state, action) => {
        const { source_country, cityData } = action?.payload?.clientInformation;
        const { latitude, longitude } = cityData?.location;
        state.countryCode = source_country?.toLowerCase();
        state.longitude = longitude;
        state.latitude = latitude;
        state.data.countryCode = source_country;
        state.data.country = cityData.country.names.en;
        state.detectedCountryCode = source_country;
        state.status = "idle";
      });
  },
});

export const {
  setUserData,
  clearUserData,
  setCountry,
  toggleEdit,
  toggleModal,
  clearErrors,
  setDetectedCountryCode,
} = userSlice.actions;

export default userSlice.reducer;
