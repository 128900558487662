import { useState } from "react";
import { isFunction } from "lodash";
import { useSelector } from "react-redux";
import MuiPhoneNumber from "material-ui-phone-number";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    "& .MuiInputBase-input": {
      background: "#fff",
    },
    "& .MuiOutlinedInput-adornedStart": {
      background: "#fff",
    },
  },
}));

const Phone = ({
  id = "phone-field",
  callback,
  initValue,
  error,
  message,
  ...props
}) => {
  const [phone, setPhone] = useState(initValue || "");
  const countryCode = useSelector((state) => state.user.countryCode);
  const classes = useStyles();

  const handleChange = (value, { countryCode }) => {
    setPhone(value);
    if (isFunction(callback)) {
      callback(value, countryCode);
    }
  };

  return (
    <div className="grid gap-2 phone-fld-grp animate-fade-in z-100">
      <MuiPhoneNumber
        defaultCountry={countryCode}
        id={"intro_phone"}
        regions={["america", "europe", "asia", "oceania", "africa"]}
        fullWidth={true}
        countryCodeEditable={false}
        onChange={handleChange}
        value={phone}
        autoFormat={true}
        variant="outlined"
        label="Mobile Number"
        placeholder={"021 452 3872"}
        disableAreaCodes={true}
        error={error}
        className={classes.textField}
        helperText={error ? message : ""}
        autoFocus
        {...props}
      />
    </div>
  );
};

export default Phone;
