import { useState } from "react";
import { isFunction } from "lodash";
import { useSelector, useDispatch } from "react-redux";

// stripe
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

// utils
import { totalPerCurrency, hasItems } from "utils";

// components
import Button from "components/Field/Button";
import Loading from "./Loading";
import Info from "components/Info";

// images
import { ReactComponent as NextIcon } from "images/east_black_24dp.svg";

// api
import { checkout } from "API";

// store
import { addOrders } from "store/orders/orderSlice";

const Payment = ({ next, order }) => {
  const [cardComplete, setCardComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const orderTotals = hasItems(order) ? totalPerCurrency(order.items) : [];
  const stripe = useStripe();
  const elements = useElements();
  const {
    address1,
    address2,
    company,
    country,
    state,
    city,
    postcode,
    email,
    phone: phoneNumber,
    firstname,
    lastname,
  } = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(
    "We encountered an issue. Please try again later."
  );
  const [error, setError] = useState(false);
  const isSignIn = useSelector((state) => state.user.data.signin);
  const addressMissing = !city || !country

  const classes = {
    base: "border border-grey-light-4 bg-white py-5 px-4 text-base w-full place-self-start",
    focus: "border-grey-light",
    invalid: "has-error",
  };

  const style = {
    base: { fontSize: "16px" },
  };

  const submitPayment = async () => {
    setLoading(true);
    setError(false);

    const card = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(card);

    if (error) {
      setLoading(false);
      setError(true);
      return false;
    }

    const res = await checkout(
      {
        paymentDetails: {
          token: token.id,
          type: "card",
        },
        shippingDetails: {
          email,
          phoneNumber,
          firstname,
          lastname,
          address1,
          address2,
          company,
          country,
          state,
          city,
          postcode,
        },
      },
      isSignIn
    );

    setLoading(false);

    if (!res.error) {
      setError(false);

      // add the order to store
      const orders = res.results?.orders?.map(({ _id, ...item }) => ({
        id: _id,
        ...item,
        status: "processed",
      }));
      dispatch(addOrders(orders));

      if (isFunction(next)) next(orders);

      return;
    }

    setError(true);
    setErrorMsg("We encountered an error. Please try again later.");
  };

  return (
    <div className="grid gap-8 text-grey-light animate-fade-in">
      <h4 className="text-blue-dark uppercase text-xl xl:text-2xl text-center font-bold">
        Payment Details
      </h4>
      <div className="grid sm:grid-cols-12 gap-x-8 gap-y-6">
        <div className="col-span-full">
          <CardElement
            options={{
              classes,
              style,
              hidePostalCode: true,
            }}
            onChange={({ complete }) => {
              setCardComplete(complete);
            }}
          />
        </div>

        {orderTotals.length > 1 && (
          <p className="col-span-full text-sm font-bold">
            Please note that as you are ordering products with multiple
            currencies, your card will be charged the total amount of each
            currency as a separate transaction on your card. Please ensure that
            you have an adequate balance on your card before you proceed.
          </p>
        )}

        <p className="col-span-full text-sm">
          * By making this purchase you are confirming that you have read and
          agreed to our{" "}
          <a
            href="/legal/terms-and-conditions-online-purchases"
            target="_blank"
            rel="noreferrer" 
            className="text-green-main underline"
          >
            Terms and Conditions of Online Sales
          </a>{" "}
          and{" "}
          <a
            href="/legal/privacy-gdpr"
            target="_blank"
            rel="noreferrer" 
            className="text-green-main underline"
          >
            Privacy Policy
          </a>
          . You are also acknowledging that the products purchased as part of
          this order are subject to the warranty contained in our{" "}
          <a
            href="https://content.gymsystems.co/pdfs/store/ubx-retil-store-product-warranty-policy.pdf"
            target="_blank"
            rel="noreferrer" 
            className="text-green-main underline"
          >
            Product Warranty Policy
          </a>
          .
        </p>
      </div>
      <div className="flex justify-end">
        <Button
          color="green-main"
          className="flex justify-center items-center space-x-2 normal-case"
          onClick={submitPayment}
          disabled={!cardComplete || !email || !phoneNumber || loading || addressMissing}
        >
          <span>Place Order Now</span>
          <NextIcon />
        </Button>
      </div>

      {error && (
        <Info open={true} color="bg-fatal-light" className="lg:w-max pl-8">
          <p>{errorMsg}</p>
        </Info>
      )}

      {loading && (
        <Loading>
          <p>Loading...</p>
        </Loading>
      )}
    </div>
  );
};

export default Payment;
