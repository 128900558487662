import { colors } from "./color.js";

export const themes = {
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.themePrimary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.themeGray,
      contrastText: colors.white,
    },
    error: {
      main: "#b71c1c",
      contrastText: colors.white,
    },
  },
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        disableElevation: true,
        borderRadius: 0,
        "&:active": {
          boxShadow: "inset 0px 0px 0px rgba(0, 40, 56, 0.4)",
        },
      },
    },
  },
};
