import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import Button from './Field/Button';

const ConfirmCountryModal = ({open, closeCB, proceedCB }) => {
  return (
    <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <div className="grid gap-6 py-6 px-2 text-center relative">
            <p>
              Changing Countries will reset your cart. Are you sure you want to proceed?
            </p>

            <div className="flex gap-4 justify-center">
              <Button variation='outline' onClick={closeCB}>Cancel</Button>
              <Button color='green-main' onClick={proceedCB} >Proceed</Button>
            </div>

            <button type='button' onClick={closeCB} className="absolute top-0 right-0 -mt-2 -mr-2">
                <CloseIcon />
              </button>
          </div>
        </DialogContent>
      </Dialog>
  )
}

export default ConfirmCountryModal