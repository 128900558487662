// component
import CommonLayout from "layout/CommonLayout";
import TrackingForm from "components/Order/TrackingForm";

const OrderTracking = () => {
  return (
    <CommonLayout>
      <TrackingForm />
    </CommonLayout>
  );
};

export default OrderTracking;
