import { isFunction } from "lodash";

export const cartAdder = (product, callback) => {
  const {
    variants = [],
    variantId: payloadVariantId,
    quantity = 1,
    id: productId,
    ...payload
  } = product;
  let [variantSelected = {}] = payloadVariantId
    ? variants.filter((variant) => variant.id === payloadVariantId)
    : variants;
  const {
    id: variantId = payloadVariantId || "",
    title: variantTitle,
    ...variantDetails
  } = variantSelected;

  // const itemId = `${productId}#${variantId}`; // itemId will be product id and the selected variant's id

  const res = {
    ...payload,
    ...variantDetails,
    quantity,
    variantTitle,
    productId,
    variantId: variantId,
  };

  if (isFunction(callback)) callback(res);

  return res;
};

export const itemsTransformer = (items) => {
  if (!items) return;

  return items.map(({ productId, variantId, quantity, selected }) => ({
    productId,
    variantId,
    quantity,
    selected,
  }));
};
