import { ReactComponent as LoadingIcon } from "images/loader.svg";

const Loading = ({ children, className }) => {
  return (
    <div
      className={`text-blue-dark text-center grid gap-8 py-12 animate-fade-in ${className}`}
    >
      {children ? children : <p>Checking your existing records...</p>}
      <div className="flex justify-center w-full animate-fade-in">
        <LoadingIcon className="animate-spin h-12 w-12 text-green-main" />
      </div>
    </div>
  );
};

export default Loading;
