import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./products/productsSlice.js";
import cartReducer from "./cart/cartSlice.js";
import userReducer from "./user/userSlice.js";
import orderReducer from "./orders/orderSlice.js";
import bannerReducer from "./bannerSlice.js";

export const store = configureStore({
  reducer: {
    products: productsReducer,
    cart: cartReducer,
    order: orderReducer,
    user: userReducer,
    banners: bannerReducer,
  },
});
