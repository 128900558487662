import { isFunction } from "lodash";
import { useSelector } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { Alert, AlertTitle } from "@material-ui/lab";

// icon
import { ReactComponent as CheckIcon } from "images/done_black_24dp.svg";

const ContactShippingSummary = ({ callback }) => {
  const handleClick = () => {
    if (isFunction(callback)) callback();
  };

  const userData = useSelector((state) => state.user.data);
  const {
    firstname,
    lastname,
    address1,
    address2,
    city,
    country,
    state,
    zip,
    email,
    phone,
    googlePlaceID,
  } = userData;
  const addressMissing = !city || !country

  const addressString =
    googlePlaceID && address1.trim()
      ? address1
      : [...new Set([address1, address2, city, state, country, zip])]
          .filter((item) => item && !!item.trim())
          .map((item) => item.trim())
          .join(", ");
  

  return (
    <section className="border border-grey-light-4 bg-white grid gap-6 p-6 animate-fade-in">
      {addressMissing && (
        <Fade in={true}>
          <Alert severity="error">
            <AlertTitle className="!font-bold">Error</AlertTitle>
            Please update your contact details and shipping address. <button onClick={handleClick} type="button" className="underline">Click here</button>.
          </Alert>
        </Fade>
      )}

      <header className="flex items-start md:items-center justify-between">
        <h5 className="flex space-x-3 items-sta-rt lg:items-center uppercase text-blue-dark">
          <span className="w-6 h-6 grid place-content-center text-green-main border border-green-main rounded-full flex-shrink-0">
            <CheckIcon className="w-3 h-3" />
          </span>{" "}
          <strong>Contact &amp; Shipping Details</strong>
        </h5>
        <button
          onClick={handleClick}
          className="text-xs text-grey-light-2 text-right underline hover:text-green-main duration-300 lg:px-3 py-2"
        >
          Edit Details
        </button>
      </header>
      <main className="grid gap-4 text-xs text-grey-light">
        <div className="grid gap-2">
          <h6 className="font-bold text-blue-dark">Ship to:</h6>
          <p className="capitalize">{`${firstname} ${lastname}`}</p>
          <p className="capitalize">{addressString}</p>
        </div>
        {email && (
          <div className="grid gap-2">
            <h6 className="font-bold text-blue-dark">Contact Email:</h6>
            <p>{email}</p>
          </div>
        )}
        {phone && (
          <div className="grid gap-2">
            <h6 className="font-bold text-blue-dark">Phone / Mobile:</h6>
            <p>{phone}</p>
          </div>
        )}

        {(!phone || !email) && (
          <p className="text-sm text-fatal">
            You need to provide your {!email ? "email" : "phone number"}.{" "}
            <button className="underline" onClick={handleClick}>
              Update Contact Details
            </button>
          </p>
        )}
      </main>
    </section>
  );
};

export default ContactShippingSummary;
