import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  status: "idle",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrder: (state, { payload }) => {
      const currentStateVal = state.value.filter((item) => item.status !== "pending");
      const date = new Date();
      state.value = [
        ...currentStateVal,
        {
          id: +date,
          items: payload,
          status: "pending",
          date: date.toString(),
        },
      ];
    },
    addOrders: (state, { payload }) => {
      if (!payload || payload.length === 0) return;

      state.value = [
        ...payload.map(({ products, ...item }) => {
          return {
            ...item,
            items: products,
          };
        }),
      ];
    },
    updateOrder: (state, { payload }) => {
      const { id, status } = payload;
      const index = state.value.findIndex((item) => item.id === id);

      if (index !== -1) {
        state.value[index].status = status;
      }

      // sort order based on date
      state.value.sort((order1, order2) => {
        const date1 = new Date(order1.date);
        const date2 = new Date(order2.date);
        return +date2 - +date1;
      });
    },
    clearOrders: (state) => {
      state.value = [];
    },
  },
});

export const { addOrder, updateOrder, addOrders, clearOrders } = orderSlice.actions;

export default orderSlice.reducer;
