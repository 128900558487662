import { Link } from "react-router-dom";
import Button from "components/Field/Button";
import { formatMoney } from "utils";

const Item = ({ item, callback }) => {
  const { id, images, title, variants } = item;
  const { currencySymbol, price, currency, stock, tax } = variants[0];

  return (
    <div className="product text-left animate-fade-in-bottom" key={id}>
      <Link
        to={`/store/${id}`}
        className="block rounded overflow-hidden mb-2.5 bg-blue-dark"
      >
        <img
          src={images[0]}
          className="object-cover hover:opacity-75 h-60 w-full duration-300"
          alt={title}
        />
      </Link>
      <Link to={`/store/${id}`}>
        <h6 className="text-blue-dark mb-1 text-lg leading-snug hover:text-green-main duration-300">
          {title}
        </h6>
      </Link>
      <p className="text-grey-light text-sm font-bold mb-2.5 uppercase">
        {currency} {currencySymbol}{formatMoney(price + (!!tax?.total ? tax?.total : 0))}
      </p>
      <Button
        disabled={stock === 0}
        text="Add to cart"
        variation="outline"
        size="sm"
        callback={callback}
      />
    </div>
  );
};

export default Item;
