import { debounce, isFunction } from "lodash";
import { useCallback, useEffect, useRef, useState, forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    "& .MuiInputBase-input": {
      background: "#fff",
    },
  },
}));

const Input = forwardRef(
  (
    {
      className,
      id,
      placeholder,
      type = "text",
      callback,
      label,
      forgotPass,
      forgotPassfn,
      focus,
      onFocus,
      onBlur,
      onClick,
      initValue,
      error = false,
      autoComplete = undefined,
      message,
      delay = 0,
      ...props
    },
    ref
  ) => {
    const el = useRef();
    const [value, setValue] = useState(initValue || "");
    const classes = useStyles();

    // eslint-disable-next-line
    const debounceCallback = useCallback(
      debounce((value) => callback(value), delay),
      [callback]
    );

    const handleInput = (e) => {
      const value = e.target.value;
      setValue(value);
      debounceCallback(value);
    };

    const fieldFocus = () => {
      if (isFunction(onFocus)) onFocus();
    };

    const fieldBlur = () => {
      if (isFunction(onBlur)) onBlur();
    };

    const fieldClick = () => {
      if (isFunction(onClick)) onClick();
    };

    const onForgotPassClick = (e) => {
      e.preventDefault();
      if (forgotPassfn) forgotPassfn();
    };

    useEffect(() => {
      if (focus) {
        el.current.focus();
      }
    }, [focus]);

    return (
      <div className={`relative grid gap-2 animate-fade-in ${className || ""}`}>
        <TextField
          variant="outlined"
          ref={ref}
          inputRef={el}
          type={type}
          classes={{
            root: classes.textField,
          }}
          placeholder={placeholder}
          id={id}
          name={id}
          autoComplete={autoComplete}
          onChange={handleInput}
          onBlur={fieldBlur}
          onFocus={fieldFocus}
          onClick={fieldClick}
          value={value}
          label={label}
          error={error}
          helperText={error ? message : ""}
          {...props}
        />
        {forgotPass && (
          <div className={`grid`}>
            <button
              type="button"
              htmlFor={id}
              className="text-green-main text-xs text-right"
              onClick={onForgotPassClick}
            >
              {forgotPass}
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default Input;
