import { useRef } from "react";
import { Link } from "react-router-dom";

// components
import Slider from "react-slick";
import Loading from "components/Checkout/Loading";

// images
import { ReactComponent as NextIcon } from "images/east_black_24dp.svg";
import { ReactComponent as PrevIcon } from "images/west_black_24dp.svg";

const SliderArrow = ({ next, className, style, onClick }) => {
  return (
    <button className={className} style={style} onClick={onClick}>
      {next ? <NextIcon /> : <PrevIcon />}
    </button>
  );
};

const Hero = ({ slides }) => {
  const el = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "hero-slider",
    nextArrow: <SliderArrow next={true} />,
    prevArrow: <SliderArrow />,
    adaptiveHeight: true,
  };

  const screens = {
    "2xl": "1535px",
    xl: "1279px",
    lg: "1023px",
    md: "767px",
    sm: "639px",
  };

  const getResponsiveImages = (image, screens) => {
    return Object.keys(image)
      .filter((key) => {
        return image[key] !== "" && key !== "value" && key !== "type";
      })
      .sort((a, b) => parseInt(screens[a]) - parseInt(screens[b]));
  };

  return (
    <section id="hero" className="bg-black leading-[.7]" ref={el}>
      {slides && slides.length > 0 ? (
        <>
          <Slider {...settings}>
            {slides
              .filter((slide) => slide.placement === "header")
              .map(({ id, image, link, name }) => (
                <div className="block leading-none" key={id}>
                  {image.type === "image" ? (
                    <Link
                      to={{
                        pathname:
                          link.type === "product"
                            ? `/store/${link.value}`
                            : link.value,
                      }}
                      target={link.type === "url" ? "_blank" : ""}
                    >
                      <div className="max-w-screen-xl mx-auto">
                        <picture>
                          {getResponsiveImages(image, screens).map(
                            (size, _, arr) => (
                              <source
                                srcSet={image[size]}
                                media={`screen and (max-width: ${screens[size]})`}
                                key={screens[size]}
                              />
                            )
                          )}
                          <img
                            src={image.value}
                            className="block mx-auto w-full h-[500px] object-cover"
                            alt={name}
                          />
                        </picture>
                      </div>
                    </Link>
                  ) : (
                    <div
                      className="relative"
                      dangerouslySetInnerHTML={{ __html: image.value }}
                    />
                  )}
                </div>
              ))}
          </Slider>
        </>
      ) : (
        <div className="min-h-[500px] grid place-content-center">
          <Loading> </Loading>
        </div>
      )}
    </section>
  );
};

export default Hero;
