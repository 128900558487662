import { isFunction } from "lodash";
import { useState, useCallback } from "react";

import { trackOrder } from "API";

const useOrderTracking = () => {
  const [trackings, setTrackings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getTrackingDetails = useCallback(async (orderId, callback) => {
    setLoading(true);
    setError(null);

    const data = await trackOrder(orderId);

    // disable loading
    setLoading(false);

    // add error details
    if (data.error) {
      setError(data.error);
      return;
    }

    setError(null);
    setTrackings(data);

    if (isFunction(callback)) callback(data);
  }, []);

  return {
    error,
    loading,
    setLoading,
    setTrackings,
    trackings,
    getTrackingDetails,
  };
};

export default useOrderTracking;
