import { Link } from "react-router-dom";
import Button from "./Field/Button";

// image
import SectionBg from "images/12rnd-people.jpg";

const GotQuestion = () => {

  return (
    <>
      <section
        className="bg-blue-dark bg-cover bg-center relative overflow-hidden"
        style={{ backgroundImage: `url(${SectionBg})` }}
      >
        <div className="absolute top-0 left-0 opacity-90 bg-blue-dark z-10 h-full w-full"></div>
        <div className="w-full xl:w-74r mx-auto py-16 px-8 text-center relative z-50 grid gap-8">
          <h2 className="text-grey-light-3 uppercase font-bold text-2.5xl">
            Questions?
          </h2>
          <div className="w-full xl:w-832 max-w-sm md:max-w-full mx-auto grid gap-4 md:grid-cols-3 xl:gap-8">
            <Link to="/store/warranty">
              <Button
                text="Warranties"
                className="w-full py-2.5"
                variation="outline"
              />
            </Link>
            <Link to="/store/faqs">
              <Button
                text="Store & Order FAQs"
                className="w-full py-2.5"
                variation="outline"
              />
            </Link>
            <Link
              to={{
                pathname: `${process.env.REACT_APP_UBX_URL}/support/store`,
                search: 'selectedTopic=Store'
              }}
              target="_blank"
            >
              <Button
                text="Contact Us"
                className="w-full py-2.5"
                variation="outline"
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default GotQuestion;
