// components
import ContentArea from "components/ContentArea";
import GotQuestion from "components/GotQuestion";

const CommonLayout = ({ children }) => {
  return (
    <>
      <ContentArea>{children}</ContentArea>
      <GotQuestion />
    </>
  );
};

export default CommonLayout;
