import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchProducts } from "API";

const initialState = {
  value: [],
  status: "idle",
  initialStateLoaded: false
};

export const productsGet = createAsyncThunk(
  "products/get",
  async ({countryCode, productId}) => {
    const response = await fetchProducts(countryCode, productId);
    return response;
  }
);

export const productsSlice = createSlice({
  name: "product",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(productsGet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(productsGet.fulfilled, (state, {payload = []}) => {
        if(!state.initialStateLoaded) state.initialStateLoaded = true

        state.status = "idle";
        state.value = payload.map(({variants, ...product}) => {
          // sort the variants by stock count
          variants = variants.sort( (a, b) => b.stock > a.stock ? 1 : -1)

          return {
            ...product,
            variants
          }
        });
      });
  },
});

export default productsSlice.reducer;
