import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { setCountry, clearUserData } from "store/user/userSlice";
import { clearOrders } from "store/orders/orderSlice";
import { countries } from "utils/countries";
import { removeSetCookies } from "utils/cookies";
import ConfirmCountryModal from "./ConfirmCountryModal";
import useCart from "hooks/useCart";

import { UBX_URL } from "config";

// icon
import { ReactComponent as CartIcon } from "images/shopping_cart_black_24dp.svg";

const Navigation = ({ links }) => {
  const defaultLinks = [
    {
      text: "Store",
      to: "",
    },
    {
      text: "Order History", // this will only be visible for signed in users
      to: "orders",
      loginOnly: true,
    },
    {
      text: "Order Tracking",
      to: "order-status",
      notLogin: true,
    },
    {
      text: "Warranty Information",
      to: "warranty",
    },
    {
      text: "STORE FAQs",
      to: "faqs",
      class: "normal-case",
    },
    {
      text: "Sign in",
      to: "signin",
      notLogin: true,
    },
  ];
  const linkItems = links || defaultLinks;
  const [active, setActive] = useState(false);
  const countryCode = useSelector((state) => state.user.countryCode);
  const detectedCountryCode = useSelector(
    (state) => state.user.detectedCountryCode
  );
  const isSignin = useSelector((state) => state.user.data.signin);
  const [selected, setSelected] = useState("PH");
  const [isSticky, setSticky] = useState(false);
  const stickyEl = useRef(null);
  const location = useLocation();
  const countryList = [
    detectedCountryCode.toUpperCase(),
    ...countries.map((country) => country.code),
  ];
  const history = useHistory();
  const [confirmUpdateCountry, setConfirmUpdateCountry] = useState(false);
  const indicatorEl = useRef(null);

  const toggleActive = () => {
    setActive(!active);
  };

  const dispatch = useDispatch();
  const { itemCount, toggleCart, emptyCart } = useCart();

  const handleClick = (e) => {
    e.preventDefault();
    toggleCart();
  };

  const onSignout = () => {
    dispatch(clearUserData());
    dispatch(clearOrders());
    removeSetCookies();
    history.push("/store/signin");
  };

  const countrySelect = (code) => {
    setSelected(code);
    setConfirmUpdateCountry(true);
  };

  const updateCountry = (code) => {
    // clear cart
    emptyCart();
    // change country
    dispatch(setCountry(code));
  };

  const closeCB = () => {
    setSelected(countryCode.toUpperCase());
    setConfirmUpdateCountry(false);
  };

  const proceedCB = () => {
    updateCountry(selected);
    setConfirmUpdateCountry(false);
  };

  useEffect(() => {
    setSelected(countryCode.toUpperCase());
  }, [countryCode]);

  // check if sticky
  useEffect(() => {
    const el = stickyEl.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        return setSticky(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    observer.observe(el);
  }, []);

  // close nav when pathname changes
  useEffect(() => {
    setActive(false);
  }, [location]);

  // trigger animationn when cart updated
  useEffect(() => {
    const indicator = indicatorEl.current;

    if (indicator) {
      if (itemCount > 2) {
        indicator.classList.add("cart-updated");
      }
      const clearAnimation = () => {
        indicator.classList.remove("cart-updated", "animate-zoom-out-enter");
      };
      indicator.addEventListener("animationend", clearAnimation);

      return () =>
        indicator.removeEventListener("animationend", clearAnimation);
    }
  }, [itemCount, indicatorEl]);

  return (
    <>
      <ConfirmCountryModal
        open={confirmUpdateCountry}
        closeCB={closeCB}
        proceedCB={proceedCB}
      />
      <nav
        ref={stickyEl}
        className={`py-3 px-0 lg:px-6 sticky lg:flex lg:justify-end -top-px left-0 border-b border-grey-light-4 text-sm z-500 bg-grey-light-6 ${
          isSticky
            ? "ease-in duration-200 lg:min-h-[64px] lg:items-center"
            : "min-h-[50px] lg:items-center"
        }`}
      >
        <button
          className="font-bold ml-4 uppercase lg:hidden"
          onClick={toggleActive}
        >
          Store Navigation
        </button>
        <a
          href={UBX_URL}
          className={`hidden lg:block ${
            isSticky
              ? "opacity-100 pointer-events-auto ease-in duration-200"
              : "opacity-0 pointer-events-none"
          } w-20 absolute left-6 top-3`}
        >
          <img
            src="https://content.gymsystems.co/hub/design-assets/svg/UBX-logo-dark.svg"
            alt="ubx"
          />
        </a>
        <div
          className={`${
            active ? "max-h-screen" : "max-h-0 lg:max-h-screen"
          } overflow-hidden duration-500`}
        >
          <ul className="uppercase block border-grey-light-4 border-t lg:border-t-0 relative top-4 lg:top-0 pb-4 lg:pb-0 lg:flex font-bold lg:flex-row justify-end overflow-hidden text-left pt-2 lg:pt-0">
            {linkItems.map((link, i) => (
              <li
                key={`link-${i}`}
                className={`block px-4 py-2 lg:py-0 border-grey-light-4 lg:border-r 
                ${link.class || ""}
                ${link.loginOnly && !isSignin ? "hidden" : ""}
                ${link.notLogin && isSignin ? "hidden" : ""}
              `}
              >
                <Link to={`/store/${link.to}`}>{link.text}</Link>
              </li>
            ))}
            {isSignin && (
              <li
                className="block px-4 py-2 lg:py-0 border-grey-light-4 lg:border-r cursor-pointer"
                onClick={onSignout}
              >
                Sign out
              </li>
            )}
          </ul>
        </div>
        <button
          className="inline-block absolute right-16 top-3 lg:relative lg:top-0 lg:right-0 pr-9 lg:pl-2 h-5 font-bold uppercase lg:border-r lg:border-grey-light-4"
          onClick={handleClick}
        >
          Cart{" "}
          <div className="absolute inline-block right-2 -top-px">
            <CartIcon />
            {itemCount > 0 && (
              <span
                ref={indicatorEl}
                className={`inline-flex items-center justify-center absolute rounded-full bg-fatal text-white font-normal text-xs text-center min-w-full min-h-full -top-3 -right-3 animate-zoom-out-enter`}
              >
                {itemCount}
              </span>
            )}
          </div>
        </button>
        <div className="country-selector absolute right-4 lg:relative top-3 lg:right-0 lg:ml-2 lg:-top-1/2">
          <ReactFlagsSelect
            countries={countryList}
            selected={selected}
            onSelect={countrySelect}
            searchable
          />
        </div>
      </nav>
    </>
  );
};

export default Navigation;
