import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { hasItems } from "utils";
import { toggleEdit } from "store/user/userSlice";
import { removeSelected } from "store/cart/cartSlice";
import useCart from "hooks/useCart";

// stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// components
import Contact from "components/Checkout/Contact";
import PersonalInfo from "components/Checkout/PersonalInfo";
import ContactShippingSummary from "components/Checkout/ContactShippingSummary";
import Payment from "components/Checkout/Payment";
import OrderSummary from "components/Checkout/OrderSummary";
import Signin from "components/Checkout/Signin";
import NotFound from "components/NotFound";
import { STRIPE_PK } from "config";

// stripe load config
const stripePromise = loadStripe(`${STRIPE_PK}`);

const Checkout = () => {
  const { isSignIn, cartUpdate, items: cartItems } = useCart();
  const [step, setStep] = useState(isSignIn ? 4 : 1);
  const history = useHistory();
  const [pendingOrder] = useSelector((state) =>
    state.order.value.filter((order) => order.status === "pending")
  );
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
  };

  const confirmOrder = (currentOrders = []) => {
    if (!currentOrders.length) return;
    dispatch(removeSelected());
    history.push(`/store/order-confirmation/`);
  };

  const signInCallback = () => {
    if (cartItems.length) cartUpdate(cartItems);
    setStep(4);
  };

  return (
    <section className="checkout bg-grey-light-3">
      {hasItems(pendingOrder) ? (
        <div className="grid lg:grid-cols-12">
          {/* left content */}
          <div className="left lg:col-span-7 px-8 md:px-12 pt-12 lg:px-16 lg:pt-16 pb-40 grid place-items-center lg:place-items-end content-start min-h-500">
            <Elements stripe={stripePromise}>
              <form
                action="#"
                onSubmit={submitHandler}
                className="content-wrap max-w-lg md:max-w-xl lg:max-w-50r w-full grid gap-8"
              >
                {step === 1 && (
                  <Signin
                    next={() => setStep(2)}
                    signInCallback={signInCallback}
                    guestCheckoutCallback={() => setStep(2)}
                  />
                )}

                {step === 2 && (
                  <Contact next={() => setStep(3)} back={() => setStep(1)} />
                )}

                {step === 3 && (
                  <PersonalInfo
                    focus={true}
                    next={() => setStep(4)}
                    back={() => setStep(2)}
                  />
                )}

                {step === 4 && (
                  <>
                    <ContactShippingSummary
                      callback={() => {
                        dispatch(toggleEdit(true));
                        setStep(2);
                      }}
                      order={pendingOrder}
                    />
                    <Payment
                      focus={true}
                      next={confirmOrder}
                      order={pendingOrder}
                    />
                  </>
                )}
              </form>
            </Elements>
          </div>

          {/* right content */}
          <div className="right lg:col-span-5 px-8 py-12 lg:px-16 lg:py-16 bg-white border-l border-grey-light-4 grid place-items-center lg:place-items-start order-first lg:order-none">
            <OrderSummary order={pendingOrder} />
          </div>
        </div>
      ) : (
        <NotFound text="No order(s) to checkout" />
      )}
    </section>
  );
};

export default Checkout;
