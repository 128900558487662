import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    flexDirection: "column",
    marginTop: 30,
    paddingRight: 30,
  },
  titleTextLbl: {
    justifyContent: "center",
  },
  phoneTextField: {
    flex: 1,
    display: "flex",
    height: 40,
    marginLeft: 6,
    marginRight: -10,
    marginTop: 15,
    marginBottom: 15,
    alignItems: "center",
    alignContent: "center",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
    "& .MuiInputBase-root": {
      background: "#fff",
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  noBorderBoxshadowAndBG: {
    border: "none",
    background: "none",
    boxShadow: "none",
  },
}));
