import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSelected, updateQuantity } from "store/cart/cartSlice";
import { formatMoney } from "utils";

// components
import Checkbox from "components/Field/Checkbox";
import Quantity from "components/Field/Quantity";

const Item = ({ item, display, onClick }) => {
  const {
    title,
    variantLabel,
    variantTitle,
    images,
    productId,
    selected,
    quantity,
    currencySymbol,
    currency,
    variantId,
    tax,
    price
  } = item;

  const dispatch = useDispatch();
  const {total: taxValue = 0} = tax || {}
  const currentPrice = price + taxValue

  return (
    <div className={`${!display ? "pl-3 relative" : ""}`}>
      {!display && (
        <Checkbox
          className="absolute top-1/2 left-0 -mt-6"
          id={variantId}
          defaultCheck={selected}
          callback={() => dispatch(toggleSelected(variantId))}
        />
      )}
      <div
        className={`bg-grey-light-3 rounded py-3 pr-2 flex flex-row w-full ${
          !display ? "pl-6" : "pl-3"
        }`}
      >
        <Link
          to={`/store/${productId}`}
          className="block flex-shrink-0"
          onClick={onClick}
        >
          <img
            src={images[0]}
            alt={title}
            className="object-cover object-top w-14.5 h-14.5 rounded"
          />
        </Link>
        <div className="item-details text-left pl-2.5 grid gap-1">
          <Link
            to={`/store/${productId}`}
            className="block item-name text-base text-blue-dark"
            onClick={onClick}
          >
            {title}
          </Link>

          <p className="font-bold text-xs text-grey-light uppercase">
            {currency} {currencySymbol + formatMoney(currentPrice)}
          </p>

          {variantLabel && variantTitle && (
            <p className="item-type text-xs text-grey-light">
              <span className="capitalize">{variantLabel.toLowerCase()}</span>:{" "}
              {variantTitle}
            </p>
          )}

          {display && (
            <p className="font-bold text-xs text-grey-light">{`QTY: ${quantity}`}</p>
          )}

          {!display && (
            <Quantity
              item={item}
              callback={(details) => dispatch(updateQuantity(details))}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;
