import { useParams } from "react-router-dom";

// components
import ContentArea from "components/ContentArea";
import Product from "components/Product";

const SingleProduct = () => {
  const { productId } = useParams();
  return (
    <ContentArea className="relative min-h-[900px]">
      <Product id={productId} />
    </ContentArea>
  );
};

export default SingleProduct;
