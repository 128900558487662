import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import App from "App";
import { store } from "store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TagManager from "react-gtm-module";
import { themes } from "./utils/themes";

const appTheme = createTheme(themes);

// tagmanager
const domainUrl = window.location.origin;
const gtmId =
  domainUrl.toLowerCase().indexOf("ubxtraining") > -1
    ? "GTM-5ZRV4QK"
    : "GTM-MF5GD95";
TagManager.initialize({ gtmId });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  </React.Fragment>
);
