import { useState, useEffect, useRef } from "react";
import { isFunction, isMatch } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setUserData, userRegister, toggleModal } from "store/user/userSlice";
import { addOrder } from "store/orders/orderSlice";
import { getIndex } from "store/helpers";
import { isEnterKey } from "utils";
import useCart from "hooks/useCart";

// country data
import { countries } from "utils/countries";

// validation
import { validateField, validateAllFields } from "utils/validations";

// components
import Input from "components/Field/Input";
import Button from "components/Field/Button";
import Loading from "components/Checkout/Loading";
import Address from "components/Field/Address";
import OTPModal from "components/OTPModal";
import AutoComplete from "components/Field/AutoComplete";

// images
import { ReactComponent as BackIcon } from "images/west_black_24dp.svg";

const PersonalInfo = ({ focus, next, back }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);
  const {
    firstname,
    lastname,
    company,
    country,
    city,
    address1,
    address2,
    state,
    zip,
    googlePlaceID,
    countryCode,
  } = userData;
  const status = useSelector((state) => state.user.status);
  const loading = status === "loading";
  const [openOtp, setOpenOtp] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [useManualAddress, setUseManualAddress] = useState(false);
  const [addressUpdated, setAddressUpdated] = useState(false);

  const fields = [
    {
      field: "firstname",
      value: firstname,
      error: false,
      isRequired: true,
    },
    {
      field: "lastname",
      value: lastname,
      error: false,
      isRequired: true,
    },
    {
      field: "country",
      value: country,
      error: false,
      isRequired: true,
      skip: false,
    },
    {
      field: "city",
      value: city,
      error: false,
      isRequired: true,
      skip: true,
    },
    {
      field: "address1",
      value: address1,
      error: false,
      isRequired: true,
      skip: true,
    },
    {
      field: "zip",
      value: zip,
      error: false,
      isRequired: true,
      skip: true,
    },
  ];
  const [fieldData, setFieldData] = useState(fields);

  const getErrorData = (field) => {
    const fieldIndex = getIndex(fieldData, field, "field");
    return fieldData[fieldIndex];
  };

  const handleClick = () => {
    const hasError = validateAllFields(fieldData, setFieldData);

    setAddressUpdated(false);
    setAddressError(false);

    // if address autocomplete is used and no googlePlaceId
    if (!useManualAddress && !googlePlaceID) {
      setAddressUpdated(true);
      setAddressError(true);
      return;
    }

    if (!hasError) {
      next();
    }
  };

  const updateUser = (field, value) => {
    dispatch(setUserData({ field, value }));
  };

  const validateIsRequired = (field, value) => {
    updateUser(field, value);
    validateField(field, value, fieldData, setFieldData, false, null, true);
  };

  const fieldCallback = (value, field, extraField, extraFieldValue) => {
    updateUser(field, value);
    if (extraField) {
      updateUser(extraField, extraFieldValue);
    }
    validateField(field, value, fieldData, setFieldData);
  };

  const goBack = () => {
    if (isFunction(back)) back();
  };

  const otpCallback = () => {
    dispatch(userRegister({ ...userData }));
    setOpenOtp(false);
    dispatch(toggleModal(false));
  };

  const otpResend = () => console.log("resend triggered");

  const otpClose = () => {
    dispatch(toggleModal(false));
    setOpenOtp(false);
  };

  const enableManualAddress = () => {
    setUseManualAddress(true);

    // fieldData clone
    const newArr = [...fieldData];

    // fields to toggle validation
    const addressFields = [
      "country",
      "city",
      "address1",
      "address2",
      "state",
      "zip",
    ];
    const fieldIndexes = addressFields.map((field) =>
      getIndex(fieldData, field, "field")
    );

    // disable single address field validation
    const [addressIndex, ...separateIndexes] = fieldIndexes;
    newArr[addressIndex] = {
      ...newArr[addressIndex],
      error: false,
      skip: true,
    };

    // enable individual address fields validation
    for (let index of separateIndexes) {
      newArr[index] = {
        ...newArr[index],
        ...fields[index],
        error: false,
        skip: false,
      };
    }

    setFieldData(newArr);
  };

  const getAddressErrors = () =>
    getErrorData("address1").error || addressError || !googlePlaceID;

  const onKeyPress = (e) => {
    if (loading) return;
    if (isEnterKey(e.key)) {
      handleClick();
    }
  };

  const { itemsSelected, cartUpdate } = useCart();
  const cachedItems = useRef(itemsSelected);

  useEffect(() => {
    if (itemsSelected.length) cartUpdate(itemsSelected, {country: countryCode, city, state});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, state, countryCode]);

  useEffect(() => {
    if(!isMatch(itemsSelected, cachedItems.current)) {
      dispatch(addOrder(itemsSelected));
      cachedItems.current = itemsSelected;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsSelected])

  return (
    <div className="w-full mx-auto lg:w-480 grid gap-6 text-grey-light animate-fade-in">
      <h4 className="text-blue-dark uppercase text-xl xl:text-2xl text-center font-bold">
        Shipping Info
      </h4>

      {openOtp && (
        <OTPModal
          callBack={otpCallback}
          closeCallback={otpClose}
          resendCallback={otpResend}
        />
      )}

      <Input
        id="firstname"
        label="First Name"
        callback={(value) => fieldCallback(value, "firstname")}
        focus={focus}
        initValue={firstname}
        error={getErrorData("firstname").error}
        message={getErrorData("firstname").message}
        onKeyPress={onKeyPress}
      />
      <Input
        id="lastname"
        label="Last Name"
        callback={(value) => fieldCallback(value, "lastname")}
        initValue={lastname}
        error={getErrorData("lastname").error}
        message={getErrorData("lastname").message}
        onKeyPress={onKeyPress}
      />

      <Input
        id="company"
        label="Company (Optional)"
        callback={(value) => {
          updateUser("company", value);
        }}
        initValue={company}
        onKeyPress={onKeyPress}
      />

      <div className="address grid gap-2">
        {!useManualAddress && (
          <>
            <Address
              label="Shipping Address"
              callback={(value, googlePlaceID) => {
                setAddressError(false);
                fieldCallback(
                  value,
                  "address1",
                  "googlePlaceID",
                  googlePlaceID
                );
                setAddressUpdated(true);
              }}
              errorCallback={() => {
                fieldCallback("", "address1", "googlePlaceID", "");
                setAddressError(true);
              }}
              initValue={
                address1 && address2
                  ? [address1, address2].join(", ")
                  : address1
              }
              error={addressUpdated ? getAddressErrors() : false}
              message={
                addressUpdated
                  ? getErrorData("address1").message || "Address not found"
                  : ""
              }
              onKeyPress={onKeyPress}
            />
          </>
        )}
        {!useManualAddress && (
          <p className="text-sm mb-2 text-blue-dark">
            Can’t find your Address?{" "}
            <button
              type="button"
              className="underline text-green-main"
              onClick={enableManualAddress}
            >
              Enter your address manually
            </button>
          </p>
        )}

        {useManualAddress && (
          <div className="grid gap-6">
            <Input
              id="address1"
              label="Address line 1"
              callback={(value) => {
                validateIsRequired("address1", value);
                setAddressUpdated(true);
              }}
              initValue={address1.trim()}
              error={getErrorData("address1").error}
              message={getErrorData("address1").message}
              onKeyPress={onKeyPress}
            />
            <Input
              id="address2"
              label="Address line 2"
              callback={(value) => {
                updateUser("address2", value);
                setAddressUpdated(true);
              }}
              initValue={address2.trim()}
              onKeyPress={onKeyPress}
            />

            <Input
              id="city"
              label="City"
              callback={(value) => validateIsRequired("city", value)}
              initValue={city}
              error={getErrorData("city").error}
              message={getErrorData("city").message}
              onKeyPress={onKeyPress}
              delay={500}
            />
            <Input
              id="state"
              label="State"
              callback={(value) => updateUser("state", value)}
              onKeyPress={onKeyPress}
              initValue={state}
              delay={500}
            />
            <Input
              id="zip"
              label="Postal Code"
              callback={(value) => validateIsRequired("zip", value)}
              onKeyPress={onKeyPress}
              initValue={zip}
              error={getErrorData("zip").error}
              message={getErrorData("zip").message}
              delay={500}
            />
            <AutoComplete
              label="Country"
              options={countries}
              optionProp="name"
              extraVal="code"
              initValue={country}
              error={getErrorData("country").error}
              message={getErrorData("country").message}
              callback={(value, extra) => {
                updateUser("country", value);
                if (extra) updateUser("countryCode", extra);
                validateIsRequired("country", value);
              }}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        <p className="text-left text-xs">
          Some products may have different shipping rates based on your country
          and delivery address. Please double check your order summary after
          adding in your shipping address.
        </p>
      </div>

      <Button
        color="green-main"
        className="flex justify-center items-center"
        onClick={handleClick}
      >
        Continue to payment
      </Button>

      {loading && (
        <Loading>
          <p>Loading....</p>
        </Loading>
      )}

      <p className="text-center hidden">
        By creating an account with us, you hereby agree to our{" "}
        <br className="hidden sm:block" />
        <a
          href="/legal/terms-and-conditions-website"
          target="_blank"
          className="text-green-main underline"
        >
          Terms
        </a>{" "}
        and{" "}
        <a
          href="/legal/privacy-gdpr"
          target="_blank"
          className="text-green-main underline"
        >
          Privacy
        </a>{" "}
        policies.
      </p>

      <div className="flex justify-center">
        <Button
          color="grey-light-2"
          variation="noBorder"
          fontWeight="normal"
          className="text-xs flex space-x-2 items-center rounded"
          callback={goBack}
        >
          <BackIcon className="w-4 h-4" />
          <span>Back to contact</span>
        </Button>
      </div>
    </div>
  );
};

export default PersonalInfo;
