import { useSelector } from "react-redux";

const Banner = () => {
  const [banner] = useSelector((state) => state.banners.value.footer);

  return (
    <section className="bg-black overflow-hidden">
      {banner && (
        <>
          {banner.image.type === "html" ? (
            <div dangerouslySetInnerHTML={{ __html: banner.image.value }} />
          ) : (
            <img src={banner.image.value} className="block mx-auto" alt="" />
          )}
        </>
      )}
    </section>
  );
};

export default Banner;
