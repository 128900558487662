import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

// component
import Input from "../Field/Input";
import Button from "../Field/Button";
import Loading from "components/Checkout/Loading";

// hooks
import useOrderTracking from "hooks/useOrderTracking";

const TrackingForm = () => {
  const history = useHistory();
  const [useNumber] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");
  const [, setPhone] = useState("");
  const [, setNumber] = useState("");
  const isSignIn = useSelector((state) => state.user.data.signin);
  const { setLoading, loading, error, getTrackingDetails } = useOrderTracking();

  const viewOrderStatus = (data) => {
    history.push(`/store/order-status/${trackingNumber}`, { data });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    getTrackingDetails(trackingNumber, viewOrderStatus);
  };

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <div className="text-center grid gap-6 py-12">
      <h1 className="text-3xl font-bold uppercase text-blue-dark">
        Check your order status
      </h1>
      <form
        action=""
        method="post"
        className="flex md:flex-row flex-col space-y-4 md:space-y-0 md:space-x-4 mx-auto max-w-[732px] w-full px-4 items-stretch"
        onSubmit={handleSubmit}
      >
        {useNumber ? (
          <>
            <Input
              id="order-number"
              placeholder="Enter  number"
              className="flex-grow relative animate-fade-in"
              callback={setNumber}
            />
            <Input
              id="phone"
              placeholder="Enter phone number"
              className="flex-grow relative animate-fade-in"
              callback={setPhone}
              type="tel"
            />
          </>
        ) : (
          <Input
            id="tracking-number"
            placeholder="Enter Order ID"
            className="flex-grow relative animate-fade-in"
            error={!!error}
            disabled={loading}
            callback={setTrackingNumber}
          />
        )}
        <Button
          text="Track "
          className="md:w-1/3 flex-shrink-0"
          type="submit"
          disabled={loading}
        />
      </form>

      {error && error.message && (
        <>
          <p className="uppercase font-bold text-fatal">{error.message}</p>
        </>
      )}

      {loading && <Loading className="gap-2 pt-8"> </Loading>}

      {isSignIn && (
        <div>
          <Link to="/store/orders">
            <button className="underline text-grey-light hover:text-blue-dark">
              View Previous Orders
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default TrackingForm;
