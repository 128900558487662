import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addOrder } from "store/orders/orderSlice";
import { formatMoney } from "utils";

import useCart from "hooks/useCart";

// components
import Item from "./Item";
import Checkbox from "components/Field/Checkbox";

// icons
import { ReactComponent as CartIcon } from "images/shopping_cart_black_24dp.svg";
import { ReactComponent as CloseIcon } from "images/close_black_24dp.svg";
import { ReactComponent as TrashIcon } from "images/delete_outline_black_24dp.svg";

const Cart = () => {
  const products = useSelector((state) => state.products.value);

  const {
    items,
    itemsSelected,
    cartIsActive,
    itemCount,
    orderSubtotals,
    toggleCart,
    toggleItems,
    removeSelectedItems,
    cleanCart,
  } = useCart();
  const dispatch = useDispatch();

  const addOrders = () => {
    toggleCart();
    dispatch(addOrder(itemsSelected));
  };

  useEffect(() => {
    cleanCart(products);
  }, [products, cleanCart]);

  return (
    <>
      {cartIsActive && (
        <>
          <section
            id="cart"
            className="fixed top-0 right-0 bg-white z-1000 w-80 max-w-full h-full shadow-md animate-slide-in-left overflow-y-scroll lg:overflow-auto"
          >
            <div className="flex flex-row justify-start items-center sticky top-0 left-0 bg-white p-4 z-200 shadow-white-blurred">
              <CartIcon />
              <h6 className="font-bold text-lg pl-1">
                Shopping Cart ({itemCount})
              </h6>
              <button className="absolute top-3 right-3" onClick={toggleCart}>
                <CloseIcon />
              </button>
            </div>
            {items.length > 0 ? (
              <>
                <ul className="m-0 relative z-100 p-4">
                  {items.map((item) => (
                    <li
                      key={`${item.productId}-${item.variantId}`}
                      className="mb-2"
                    >
                      <Item item={item} onClick={toggleCart} />
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <div className="bg-grey-light-3 rounded text-sm p-4 text-center">
                <p>There's nothing on your cart.</p>
                <p>
                  Go to{" "}
                  <Link
                    to="/store"
                    className="underline text-green-main"
                    onClick={toggleCart}
                  >
                    store
                  </Link>
                </p>
              </div>
            )}
            {items && items.length > 0 && (
              <div className="sticky bg-white bottom-0 left-0 z-200 shadow-white-blurred">
                <div className="flex flex-row flex-wrap justify-between items-start text-blue-dark pb-3 pt-2 px-4">
                  <Checkbox
                    id="all-items"
                    label="All"
                    callback={toggleItems}
                    defaultCheck={
                      itemsSelected && itemsSelected.length === items.length
                    }
                  />
                  {orderSubtotals.length > 0 && (
                    <div className="flex pl-6">
                      <p className="pr-1">Subtotal:</p>
                      <ul>
                        {orderSubtotals.map((order, i) => (
                          <li
                            key={order.currency + i}
                            className="text-right uppercase"
                          >
                            <strong>
                              {order.currency} {order.currencySymbol}
                              {formatMoney(order.subtotalNoPostage)}
                            </strong>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <div className="border-t border-grey-light-2 mt-4 text-blue-dark opacity-70 pt-3 pb-0 text-sm px-4">
                * Shipping will be calculated upon checkout
                </div>

                <div className="flex justify-between items-center border-t border-grey-light-4 mt-3 p-4">
                  {itemsSelected && itemsSelected.length > 0 && (
                    <>
                      <button
                        className="flex items-center text-xs text-blue-dark opacity-50 hover:opacity-100 duration-300 animate-fade-in"
                        onClick={removeSelectedItems}
                      >
                        <div className="mr-2 rounded-full border border-grey-light-4 flex items-center justify-center w-8 h-8">
                          <TrashIcon className="w-4 h-4" />
                        </div>
                        Remove Selected
                      </button>
                      <Link
                        to="/store/checkout"
                        className="text-white py-3 px-5 text-base font-bold uppercase bg-green-main duration-300 animate-fade-in"
                        onClick={addOrders}
                      >
                        Checkout
                      </Link>
                    </>
                  )}
                </div>
              </div>
            )}
          </section>
          <div
            id="cart-backdrop"
            className={`fixed left-0 top-0 z-999 w-full h-full bg-opacity-70 bg-black animate-fade-in cursor-pointer`}
            onClick={toggleCart}
          ></div>
        </>
      )}
    </>
  );
};

export default Cart;
