import Step from "./Step";

const Steps = ({ steps = [] }) => {
  const stepCount = steps.length;

  return (
    <div className="flex justify-center text-center">
      {steps.map((step, i) => (
        <Step
          isFirst={i === 0}
          isLast={i === stepCount - 1}
          isActive={step.active}
          name={step.name}
          key={`progress-step-${i}`}
          className={`progress-step-${i}`}
        >
          <step.icon className="w-6 h-6 lg:w-8 lg:h-8" />
        </Step>
      ))}
    </div>
  );
};

export default Steps;
