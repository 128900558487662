import { useEffect, useState } from "react";
import parse from "html-react-parser";

// component
import CommonLayout from "layout/CommonLayout";
import Form from "components/Faqs/Form";
import Item from "components/Faqs/Item";
import Loading from "components/Checkout/Loading";

// api
import { fetchFaqs } from "API";

// utils
import { htmlToString } from "utils";

const Faqs = () => {
  const [faqItems, setFaqItems] = useState([]);
  const [filtered, setFiltered] = useState(faqItems);
  const [currentActive, setCurrentActive] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);

  const faqClick = (index) => {
    if (index === currentActive) {
      setCurrentActive(-1);
      return;
    }
    setCurrentActive(index);
  };

  /**
   *
   * search for the faqs if there's any answers that exists
   */
  const filterFaqs = (string) => {
    setCurrentActive(-1);
    const filtered = faqItems.filter((item) => {
      let inAnswer = false;

      try {
        if (typeof item.answer.props.children === "object") {
          inAnswer = item.answer.props.children.find((item) => {
            const answer = (
              typeof item.props.children === "object"
                ? item.props.children.join(" ")
                : item.props.children
            ).toLowerCase();
            return answer.includes(string);
          });
        }
      } catch (err) {
        console.error("faq not found");
      }

      return inAnswer || item.question.toLowerCase().includes(string);
    });
    setFiltered(filtered);
  };

  const getFaqs = async () => {
    const res = await fetchFaqs();
    setFaqItems(res);
    setFiltered(res);
    setIsLoading(false);
  };

  useEffect(() => {
    getFaqs();
  }, []);

  useEffect(() => {
    if(filtered.length === 1) setCurrentActive(0)
  }, [filtered])

  return (
    <>
      <CommonLayout>
        <div className="text-center grid gap-4 lg:gap-6 lg:px-6 py-12">
          <h1 className="text-3xl font-bold uppercase text-blue-dark xl:py-4">
            Store Faqs
          </h1>

          <Form callback={filterFaqs} />

          {filtered.length >= 1 ? (
            filtered.map(({ question, answer }, index) => (
              <div onClick={faqClick.bind(null, index)} key={`faq-${index}`}>
                <Item
                  question={htmlToString(question)}
                  children={<>{parse(answer)}</>}
                  active={currentActive === index}
                  index={index}
                />
              </div>
            ))
          ) : (
            <>
              {isLoading ? (
                <Loading>
                  <p>Loading faqs</p>
                </Loading>
              ) : (
                <h1>No Items found</h1>
              )}
            </>
          )}
        </div>
      </CommonLayout>
    </>
  );
};

export default Faqs;
