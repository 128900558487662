import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { productsGet } from "store/products/productsSlice.js";
import { isNull } from "lodash";

// components
import Details from "./Details";
import ImageBlock from "./ImageBlock";
import NotFound from "components/NotFound";
import Loading from "components/Checkout/Loading";

const Product = ({ id }) => {
  const { value: products, status } = useSelector((state) => state.products);
  const {
    countryCode,
    data: { country },
  } = useSelector((state) => state.user);
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const isLoading = status === "loading";

  useEffect(() => {
    const updateProduct = () => {
      if (products.length > 0) {
        const [currentProduct] = products.filter((product) => {
          const result = product.id === id;
          return result;
        });
        setProduct(currentProduct);
        return
      }
      setProduct(undefined)
    }
    updateProduct()
  }, [products, id, product, countryCode]);

  useEffect(() => {
    if (countryCode) {
      dispatch(productsGet({ countryCode }));
      setProduct(null);
    }
  }, [dispatch, country, countryCode, setProduct]);

  return (
    <>
      {isLoading && (
        <div className="py-12">
          <Loading>Loading product details...</Loading>
        </div>
      )}
      {!isLoading && countryCode && (
        <>
          {product && (
            <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between pb-8 -mx-6 -mt-6 lg:mx-0 lg:mt-0 xl:py-8">
              <ImageBlock product={product} />
              <Details product={product} />
            </div>
          )}
          {!product && !isNull(product) && (
            <NotFound>
              <h6 className="text-2xl font-bold mb-6 uppercase opacity-70">
                Product Not found.
              </h6>
              <p className="text-sm mx-auto max-w-[400px] mb-4 opacity-70">
                Sorry, the product you are viewing is not currently available in
                the country we have detected you're visiting form ({country}).
              </p>
              <p>
                <Link to="/store" className="text-green-main text-base underline">
                  Return to store home
                </Link>
              </p>
            </NotFound>
          )}
        </>
      )}
    </>
  );
};

export default Product;
