import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import CommonLayout from "layout/CommonLayout";
import Info from "components/Info";
import Display from "components/Order/Display";
import NotFound from "components/NotFound";
import Loading from "components/Checkout/Loading";
import Button from "components/Field/Button";
import { ReactComponent as BackIcon } from "images/west_black_24dp.svg";

const OrderConfirmation = () => {
  const [loading, setLoading] = useState(true);
  const {current: orders} = useRef(useSelector((state) => {
    return state.order.value.filter((order) => order.status === "processed");
  }));
  

  useEffect(() => {
    if(orders) {
      setLoading(false)
    }
  }, [orders]);

  return (
    <CommonLayout>
      <section className="grid gap-6 text-center pt-4 pb-16 lg:px-4">
        {orders && orders.length ?
          <Info open={true} color="bg-green-light" className="lg:w-max pl-8">
            <p>
              You will recieve an email with further confirmation details shortly.
            </p>
            <p>
              Please keep a copy of your Order ID &amp; email should you require
              additional support relating to this order.
            </p>
          </Info> : ''
        } 

        <div className="md:flex items-center lg:w-900 w-full mx-auto md:pr-44">
          <Link to="/store/orders">
            <Button
              color="grey-light-2"
              variation="noBorder"
              fontWeight="normal"
              className="text-xs flex space-x-2 items-center rounded w-44"
            >
              <BackIcon className="w-4 h-4" />
              <span>Previous Orders</span>
            </Button>
          </Link>
          <h1 className="text-2xl font-bold uppercase text-blue-dark flex-grow">
            Order Status
          </h1>
        </div>

        {loading ? (
          <Loading>
            <p>Loading order details...</p>
          </Loading>
        ) : (
          <>
            {orders  && orders.length ? (
              <>
                {orders.map(order => <Display order={order} key={order.id} />)}
              </>
            ) : (
              <NotFound text="No orders yet" />
            )}
          </>
        )}
      </section>
    </CommonLayout>
  );
};

export default OrderConfirmation;
