import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCart } from "API";
import { getIndex } from "store/helpers";
import cookies from "utils/cookies";

const initialState = {
  value: cookies.get("cartItems") || [],
  status: "idle",
  active: false,
  replaced: false
};

export const cartGet = createAsyncThunk(
  "cart/get",
  async (isSignIn = false) => {
    const response = await fetchCart(isSignIn);
    return response;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCart: (state, { payload }) => {
      const { quantity = 1, productId, variantId, ...product } = payload;
      const currentItem = state.value.filter(
        ({ productId: itemId, variantId: itemVariantId }) =>
          itemId === productId && variantId === itemVariantId
      );
      const otherItems = state.value.filter(
        ({ variantId: itemVariantId }) => variantId !== itemVariantId
      );

      if (currentItem.length > 0) {
        state.value = [
          ...otherItems,
          {
            ...currentItem[0],
            quantity: currentItem[0].quantity + quantity,
          },
        ];
      } else {
        state.value = [
          ...state.value,
          { ...product, quantity, productId, variantId, selected: true },
        ];
      }
    },
    toggleActive: (state, { payload }) => {
      if (payload) {
        state.active = payload;
        return;
      }
      state.active = !state.active;
    },
    toggleSelected: (state, { payload }) => {
      const index = getIndex(state.value, payload, "variantId");

      if (index !== -1) {
        state.value[index].selected = !state.value[index].selected;
      }
    },
    toggleSelectAll: (state) => {
      const hasNotSelected = state.value.some((item) => !item.selected);

      state.value = state.value.map((item) => ({
        ...item,
        selected: hasNotSelected,
      }));
    },
    updateQuantity: (state, { payload }) => {
      const { variantId, quantity } = payload;
      const index = getIndex(state.value, variantId, "variantId");

      if (index !== -1) {
        state.value[index].quantity = quantity;
      }
    },
    removeSelected: (state) => {
      state.value = state.value.filter((item) => !item.selected);
    },
    clearCart: (state) => {
      state.value = [];
    },
    // remove unavailable products from the cart
    sanitizeCart: (state, { payload: products }) => {
      if (!products.length) return;

      const availableVariants = products.reduce((a, b) => {
        return [...a, ...b.variants.map(({ id }) => id)];
      }, []);
      state.value = state.value.filter(({ variantId: id }) => {
        const itemExist = availableVariants.includes(id);
        return itemExist;
      });
    },
    setCartReplacedStatus: (state, {payload}) => {
      state.replaced = payload;
    },
    // replace cart value with the new one
    // this will update the postage, tax and other calculations from the backend
    replaceCart: (state, { payload }) => {
      state.value = payload;
      state.replaced = true
    },
    updateCartStatus: (state, { payload }) => {
      state.status = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(cartGet.fulfilled, (state, { payload }) => {
      // if there's saved cart items from cookies ignore the payload
      // the getCart api call will only get triggered once, only if the user signin
      if (state.value.length) return;

      // if there's no saved cart items replace the cart with the one from api
      if (!state.value.length) state.value = payload;
    });
  },
});

export const {
  toggleActive,
  toggleSelected,
  updateQuantity,
  toggleSelectAll,
  removeSelected,
  updateCart,
  clearCart,
  sanitizeCart,
  replaceCart,
  setCartReplacedStatus,
  updateCartStatus
} = cartSlice.actions;

export default cartSlice.reducer;
