import { STORE_BUCKET } from "config";
import { formatMoney } from "utils";

const Item = ({ item }) => {
  return (
    <div className="content-start grid gap-2 pl-28 md:pl-0 md:gap-0 md:flex md:space-x-4 items-start text-grey-light relative min-h-24">
      <div className="absolute left-0 top-0 md:relative w-24 h-24 rounded-md overflow-hidden pb border border-grey-light">
        <img
          src={item.thumbImage || `${STORE_BUCKET}/${item.image}`}
          alt={item.title}
          className="object-cover object-top h-full"
        />
      </div>
      <div className="md:flex-grow grid gap-1 text-sm pb-2 md:pt-4">
        <p className="text-blue-dark text-base">{item.title}</p>
        {item.variantTitle && <p>{item.variantTitle}</p>}
        {item.type && <p>{item.type}</p>}
      </div>
      <div className="text-sm md:w-32 xl:w-40 md:text-center font-bold md:pt-4 uppercase">
        {`${item.currency} ${item.currencySymbol}${formatMoney(item.price)}`}
      </div>
      <div className="text-sm md:w-32 xl:w-40 md:text-right font-bold md:pt-4">
        Qty: {item.quantity}
      </div>
    </div>
  );
};

export default Item;
