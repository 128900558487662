import Cookies from "universal-cookie";
import { v4 as uuid } from "uuid";
const cookies = new Cookies();

var date = (hr = 1) => new Date(Date.now() + 60 * 60 * hr * 1000); // 1hr

const cookieConfig = {
  expires: date(),
  path: "/",
};

export const userData = cookies.get("userData") || {};
export const apiConfig = cookies.get("apiConfig") || {};
export const guestId = cookies.get("guestId") || uuid();

const cookieSetter = (field, value, config) =>
  cookies.set(field, value, {
    ...cookieConfig,
    ...config,
  });

export const setUserDataCookie = (value) => cookieSetter("userData", value);
export const setApiConfigCookie = (value) => cookieSetter("apiConfig", value);
export const removeSetCookies = () => {
  cookies.remove("userData", cookieConfig);
  cookies.remove("apiConfig", cookieConfig);
};

// set guestId
export const setGuestIdCookie = (value) =>
  cookieSetter("guestId", value, { expires: date(168) }); // 1 week
setGuestIdCookie(guestId);

// cart cookies
export const setCartCookies = (value) => {
  cookieSetter("cartItems", value, { expires: date(120 * 24) }); // 120 days
};

export default cookies;
