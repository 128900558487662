import { useState } from "react";

// icon
import { ReactComponent as CloseIcon } from "images/close_black_24dp.svg";

const Info = ({
  children,
  className,
  open = false,
  color = "bg-grey-light-4",
  disableClose = false,
}) => {
  const [active, setActive] = useState(open);
  const closeAlert = () => {
    setActive(false);
  };
  const activeClass = active
    ? "opacity-100 max-h-screen"
    : "opacity-0 max-h-0 py-0";

  return (
    <div
      className={`text-center text-sm relative w-full mx-auto p-4 pr-14 rounded text-blue-dark duration-300 overflow-hidden ${color} ${
        className || ""
      } ${activeClass}`}
    >
      {!disableClose && (
        <CloseIcon
          className="absolute right-4 top-4 text-grey-light cursor-pointer"
          onClick={closeAlert}
        />
      )}

      {children}
    </div>
  );
};

export default Info;
