import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchBanners } from "API";

const initialState = {
  value: {
    header: [],
    footer: [],
  },
  status: "idle",
  active: false,
};

export const bannersGet = createAsyncThunk("banners/get", async () => {
  const { data } = await fetchBanners();
  return data;
});

export const bannerSlice = createSlice({
  name: "cart",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(bannersGet.fulfilled, (state, action) => {
      state.value.header = action.payload?.filter(
        ({ placement }) => placement === "header"
      );
      state.value.footer = action.payload?.filter(
        ({ placement }) => placement === "footer"
      );
    });
  },
});

export default bannerSlice.reducer;
