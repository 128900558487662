import { useSelector, useDispatch } from "react-redux";

// store
import { updateCart } from "store/cart/cartSlice";

// components
import Item from "components/Product/Item";
import Loading from "components/Checkout/Loading";
import NotFound from "components/NotFound";

// utils
import { cartAdder } from "utils/cart";

const Grid = () => {
  const { value: products, initialStateLoaded } = useSelector(
    (state) => state.products
  );
  const status = useSelector((state) => state.products.status);
  const {country} = useSelector((state) => state.user.data)
  const { countryCode } = useSelector((state) => state.user);
  const loading = status === "loading";
  const dispatch = useDispatch();

  const cartAdderCb = (payload) => {
    dispatch(updateCart(payload));
  };

  return (
    <section
      className={`store-grid border-t border-grey-light-4 border-solid py-6 grid gap-x-6 gap-y-6 md:gap-x-8 md:gap-y-8 $grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 auto-rows-fr min-h-500 relative`}
    >
      {products.length > 0 &&
        products.map((product) => (
          <Item
            item={product}
            key={product.id}
            callback={() =>
              cartAdder({ productId: product.id, ...product }, cartAdderCb)
            }
          />
        ))}
      {countryCode && !loading && products.length === 0 && (
        <div className="text-center py-16 col-span-full">
          <NotFound>
            <h6 className="text-2xl font-bold mb-6 uppercase opacity-70">
              No products found.
            </h6>
            <p className="text-sm mx-auto max-w-[400px] mb-4 opacity-70">
              Sorry, there are no product currently available in
              your country. We have detected you're visiting form ({country}).
            </p>
            <p className="text-sm mx-auto max-w-[400px] mb-4 opacity-70">
            To view products in an alternate region, please change the country in the top right corner of this page.
            </p>
          </NotFound>
        </div>
      )}
      {loading && (
        <div
          className={`flex flex-row justify-center items-center font-bold text-grey-light text-lg col-span-full absolute left-0 top-0 w-full h-full bg-opacity-80 ${
            initialStateLoaded ? "bg-white" : ""
          }`}
        >
          <Loading>
            <p>{initialStateLoaded ? "Updating" : "Loading"} Products</p>
          </Loading>
        </div>
      )}
    </section>
  );
};

export default Grid;
