import { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { Alert, AlertTitle } from "@material-ui/lab";
import { formatMoney } from "utils";

// store
import { updateCart } from "store/cart/cartSlice";
import { addOrder } from "store/orders/orderSlice";

// components
import Quantity from "components/Field/Quantity";
import Button from "components/Field/Button";
import Info from "components/Info";

// utils
import { cartAdder } from "utils/cart";

const Details = ({ product }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { title, variantLabel, variants, description, alert } = product;
  const [selectedProduct, setSselectedProduct] = useState();
  const [selectedVariant, setSelectedVariant] = useState(variants[0]);
  const { stock, id: variantId, minQty = 1 } = selectedVariant;
  const currentQuantity =
    stock === null || (typeof stock === "number" && stock > minQty)
      ? minQty
      : 0;
  const [itemCount, setItemCount] = useState(1);
  const {
    currencySymbol,
    currency,
    shipping,
    id: firstVariant,
    total,
    price,
    tax,
    postage,
  } = selectedVariant;
  const payloadData = useMemo(
    () => ({ ...product, variantId: selectedProduct, quantity: itemCount }),
    [product, selectedProduct, itemCount]
  );

  const handleCartAdder = () => {
    cartAdder(payloadData, (payload) => dispatch(updateCart(payload)));
  };

  const handleBuyNow = () => {
    cartAdder(payloadData, (payload) => {
      dispatch(updateCart(payload));
      dispatch(addOrder([payload]));
    });
    history.push("/store/checkout");
  };

  const variantSelect = (variant) => (e) => {
    e.preventDefault();
    const { id } = variant;
    setSselectedProduct(id);
    setSelectedVariant(variant);
  };

  const displayAmount = (amount) => {
    return `${currency} ${currencySymbol}${formatMoney(amount)}`;
  };

  // set default value for the selectedProduct
  useEffect(() => {
    setSselectedProduct(firstVariant);
  }, [firstVariant, variants]);

  return (
    <div className="w-full product-details px-6 pt-6 lg:pl-8 xl:pt-0 xl:pr-0 max-w-full ">
      {alert && (
        <Fade in={true}>
          <Alert severity="info" className="mb-6">
            <AlertTitle className="!font-bold">{alert.title}</AlertTitle>
            {alert.description}
          </Alert>
        </Fade>
      )}

      {stock === 0 && (
        <Info open={true} className="w-full max-w-[800px] mb-8" disableClose>
          <p>
            There is currently no available stock for the selected Variation of
            the product. Please check back soon, or contact us for further
            details.
          </p>
        </Info>
      )}

      <h1 className="text-blue-dark text-2.5xl font-bold mb-4">{title}</h1>
      <p className="text-xl text-blue-dark mb-4 uppercase">
        {displayAmount(price)}
      </p>

      <div className="mb-4 flex flex-wrap justify-start items-baseline space-x-1 space-y-1">
        {!!tax?.total && tax?.total !== 0 && (
          <span className="text-grey-light uppercase text-xs font-bold text px-2 py-1 rounded-sm border border-grey-light-4 inline-flex">
            Tax{tax.label ? ` (${tax.label})` : ''}: {displayAmount(tax.total)}
          </span>
        )}
        {postage ? (
          <span className="text-grey-light uppercase text-xs font-bold text px-2 py-1 rounded-sm border border-grey-light-4 inline-flex">
            Postage: {displayAmount(postage)}
          </span>
        ) : null}
        {shipping ? (
          <span className="text-grey-light uppercase text-xs font-bold text px-2 py-1 rounded-sm border border-grey-light-4 inline-flex">
            Shipping: {displayAmount(shipping)}
          </span>
        ) : null}
      </div>
        
      {/* display total price only if there's tax and postage  */}
      {((!!tax?.total && tax?.total !== 0) || !!postage) && (
        <div className="detail-block mb-7 w-full">
          <p className="font-bold mb-1 text-grey-light text-sm">Total:</p>
          <p className="text-xl text-grey-light uppercase">
            {displayAmount(total)}
          </p>
        </div>
      )}

      <div className="detail-block mb-7 w-full">
        <p className="font-bold mb-4 text-grey-light text-sm">Quantity</p>
        <Quantity
          item={{ ...product, variantId, quantity: currentQuantity }}
          callback={({ quantity }) => setItemCount(quantity)}
        />
      </div>

      {variants && variants.length > 1 && (
        <div className="detail-block mb-7">
          <p className="font-bold mb-4 text-grey-light text-sm capitalize">
            {variantLabel?.toLowerCase() || "Variants"}
          </p>
          <div className="flex gap-3">
            {variants.map((variant) => (
              <Button
                variation="outline"
                size="sm"
                text={variant.title}
                key={variant.id}
                style={
                  selectedProduct === variant.id
                    ? { color: "#fff", background: "#73B944" }
                    : null
                }
                onClick={variantSelect(variant)}
              />
            ))}
          </div>
        </div>
      )}

      <div className="detail-block mb-7">
        <div className="flex gap-4 items-stretch">
          <Button
            text="Add to cart"
            variation="outline"
            color="grey-light"
            className="w-44 py-2.5 px-2.5 disabled:bg-transparent"
            onClick={handleCartAdder}
            disabled={stock === 0 || (!!stock && itemCount > stock)}
          />
          <Button
            text="Buy now"
            color="green-main"
            className="w-44 py-2.5 px-2.5"
            onClick={handleBuyNow}
            disabled={stock === 0 || (!!stock && itemCount > stock)}
          />
        </div>
      </div>

      <div className="detail-block mb-7 text-blue-dark">
        <p className="font-bold mb-4 text-sm">Product Description</p>

        <div
          className="grid gap-4 product-details"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      </div>
    </div>
  );
};

export default Details;
