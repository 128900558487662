// images
import { ReactComponent as BtnIos } from "images/download-ios.svg";
import { ReactComponent as BtnAndroid } from "images/download-android.svg";

const AppDownloads = () => {
  return (
    <div className="app-downloads text-grey-light pt-6 md:pt-8">
      <div className="flex items-center justify-center space-x-2.5">
        <a
          href="https://apps.apple.com/au/app/train-on-demand/id1505678506#?platform=iphone"
          target="_blank"
          className="block w-40 h-14"
          rel="noopener noreferrer"
        >
          <BtnIos />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.trubx.train"
          target="_blank"
          className="block w-40 h-14"
          rel="noopener noreferrer"
        >
          <BtnAndroid />
        </a>
      </div>
      <p className="text-xs text-center">
        * Non club members require full premium access to use Equipment Packs.
      </p>
    </div>
  );
};

export default AppDownloads;
