import React from "react";

const Button = ({
  className,
  type = "button",
  variation = "default",
  color = "green-main",
  textColor = "white",
  hoverColor = "blue-dark",
  size = "default",
  text,
  callback,
  children,
  fontWeight = "bold",
  disabled,
  ...props
}) => {
  const commonStyle = `font-${fontWeight} uppercase ${
    variation !== "noBorder" ? `border-2 border-${color}` : ""
  } text-center duration-300`;

  const disableStyle =
    "disabled:opacity-20 disabled:bg-grey-light disabled:cursor-not-allowed disabled:border-grey-light disable:shadow-none";

  const variationClasses = {
    default: `bg-${color} text-${textColor} hover:border-${textColor} hover:shadow-border-${color}`,
    outline: `bg-transparent text-${color} hover:bg-${color} hover:text-${textColor}`,
    noBorder: `bg-transparent text-${color} hover:bg-${color} hover:bg-opacity-10 hover:text-${hoverColor}`,
  };
  const btnSize = {
    default: `py-2 px-4`,
    sm: `py-1 px-3 text-sm`,
    xs: `py-1 px-2 text-xs`,
    lg: `px-2.5 py-2.5`,
  };
  const handleClick = (e) => {
    if (callback) callback(e);
  };
  return (
    <button
      className={`
        ${commonStyle} 
        ${variationClasses[variation]} 
        ${btnSize[size]}
        ${className || ""} 
        ${disableStyle}
      `}
      type={type}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {text || children}
    </button>
  );
};

export default Button;
