import { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import CommonLayout from "layout/CommonLayout";
import Info from "components/Info";
import Steps from "components/Progress/Steps";
import TravelHistory from "components/TravelHistory";
import Loading from "components/Checkout/Loading";
import Button from "components/Field/Button";

//hooks
import useOrderTracking from "hooks/useOrderTracking";

// icons
import { ReactComponent as CartIcon } from "images/shopping_cart_black_24dp.svg";
import { ReactComponent as PackageIcon } from "images/package.svg";
import { ReactComponent as ShippingIcon } from "images/local_shipping_black_24dp.svg";
import { ReactComponent as DoneIcon } from "images/done_black_24dp.svg";

const OrderStatus = () => {
  const [steps, setSteps] = useState([
    {
      active: true,
      name: "Ordered",
      icon: CartIcon,
    },
    {
      active: false,
      name: "Shipped",
      icon: PackageIcon,
    },
    {
      active: false,
      name: "In transit",
      icon: ShippingIcon,
    },
    {
      active: false,
      name: "Delivered",
      icon: DoneIcon,
    },
  ]);
  const activeSteps = steps.filter(({active}) => !!active).length

  const {
    trackings,
    loading,
    setLoading,
    error,
    getTrackingDetails,
    setTrackings,
  } = useOrderTracking();

  const {
    location: { state: orderState },
  } = useHistory();

  const { orderId } = useParams();

  const updateSteps = (trackings) => {
    if (!trackings || !trackings.length) return;

    // check if all packages is delivered
    const delivered = trackings.every(
      ({ trackingData: { subtag_message } }) =>
        subtag_message.toLowerCase() === "delivered"
    );
    // check if there's pending delivery
    const pending = trackings.some(
      ({ trackingData: { subtag_message } }) =>
        subtag_message.toLowerCase() === "in transit"
    );
    // check if either p
    const shipped = trackings.some(
      ({ trackingData: { subtag_message } }) =>
        subtag_message.toLowerCase() === "delivered"
    );

    setSteps((prevState) => {
      const newState = prevState.map((obj, i) => {
        if (delivered) {
          return {
            ...obj,
            active: true,
          };
        }

        if (pending) {
          if (i < 3) {
            return { ...obj, active: true };
          }
        }

        if (shipped) {
          if (i < 2) {
            return { ...obj, active: true };
          }
        }

        return obj;
      });
      return newState;
    });
  };

  useEffect(() => {
    if (orderId && !orderState) getTrackingDetails(orderId, updateSteps);

    if (orderState) {
      setLoading(false);
      // add the array from tracking form
      updateSteps(orderState.data);
      setTrackings(orderState.data);
    }
  }, [orderId, orderState, getTrackingDetails, setLoading, setTrackings]);

  return (
    <CommonLayout>
      <section className="grid gap-6 text-center py-4 lg:py-6 lg:px-4">
        {!loading && activeSteps === 1 && (
          <Info open={true} className="w-full max-w-[800px]" disableClose>
            <p>
              If you just received your tracking number, it may take 3-5
              business days for our carrier to activate your tracking number.
              Thanks for your patience!
            </p>
          </Info>
        )}

        <h1 className="text-3xl font-bold uppercase text-blue-dark py-4">
          Order Status
        </h1>

        {loading && (
          <Loading className="gap-2 pt-8">
            <p className="uppercase font-bold text-blue-dark">
              Loading order details...
            </p>
          </Loading>
        )}

        {error && error.message && (
          <>
            <p className="uppercase font-bold text-fatal">{error.message}</p>
            <Link to="/store/">
              <Button
                text="Go back to store"
                variation="outline"
                size="sm"
                color="blue-dark"
              />
            </Link>
          </>
        )}

        {trackings && !loading && <Steps steps={steps} />}

        <div className="lg:max-w-[800px] w-full mx-auto lg:px-4 py-6 flex flex-col md:flex-row gap-6">
          {trackings && !loading && (
            <>
              <TravelHistory className="w-full" trackings={trackings} />
            </>
          )}
        </div>
      </section>
    </CommonLayout>
  );
};

export default OrderStatus;
