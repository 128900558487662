import { totalPerCurrency, hasItems } from "utils";
import { useSelector } from "react-redux";
import Loading from "./Loading";

// components
import Item from "components/Cart/Item";
import TotalSection from "components/TotalSection";

// image
import { ReactComponent as CartIcon } from "images/shopping_cart_black_24dp.svg";

const OrderSummary = ({ shipping, order }) => {
  const orderTotals = hasItems(order) ? totalPerCurrency(order.items) : [];
  const status = useSelector((state) => state.cart.status);
  const isLoading = status === "loading";

  return (
    <div className="w-full max-w-lg md:max-w-xl xl:max-w-sm grid gap-4 text-blue-dark">
      <h2 className="flex space-x-2.5 items-center text-lg ">
        <CartIcon className="text-grey-light" />
        <strong>Order Summary</strong>
      </h2>

      <div className="grid gap-4 relative">
        {order &&
          order.items.map((item) => (
            <Item
              item={item}
              display={true}
              key={`${item.productId}-${item.variantId}`}
            />
          ))}

        {shipping && (
          <p className="flex items-center justify-between text-sm pb-6">
            Shipping:
            <strong>{shipping}</strong>
          </p>
        )}
        {/* tax and postage display */}
        <hr className="border-grey-light-4" />
        {orderTotals.length && (
          <>
            <TotalSection
              title="Subtotal"
              field="subtotal"
              orderTotals={orderTotals}
            />
            <TotalSection
              title="Shipping"
              field="postageTotal"
              orderTotals={orderTotals}
            />
            <TotalSection
              title="Tax"
              field="taxTotal"
              orderTotals={orderTotals}
            />
          </>
        )}

        {/* total display */}
        <hr className="border-grey-light-4" />
        <TotalSection
          title="Total"
          field="total"
          orderTotals={orderTotals}
          className="text-base !opacity-100"
        />

        {isLoading && (
          <div className="absolute -left-6 top-0 -right-6 h-full bg-white bg-opacity-80 backdrop-blur-sm grid place-content-center">
            <Loading>
              <p className="max-w-[200px] mx-auto">Recalculating total based on your address</p>
            </Loading>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
