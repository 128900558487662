import { useSelector } from "react-redux";

import Hero from "components/Hero/";
import ContentArea from "components/ContentArea";
import Grid from "components/Store/Grid";

const Store = () => {
  const slides = useSelector((state) => state.banners.value.header);

  return (
    <>
      {slides && <Hero slides={slides} />}
      <ContentArea>
        <Grid />
      </ContentArea>
    </>
  );
};

export default Store;
