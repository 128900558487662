const SectionHighlight = ({ before, after }) => {
  return (
    <>
      {before && <div className="w-px h-12 bg-grey-light-2 mx-auto my-6"></div>}
      <div className="section-highlight w-700 max-w-full text-center mx-auto border border-green-main rounded-md p-4 lg:p-8">
        <p className="font-bold mb-2.5">
          Purchase any Equipment Pack from the UBX Global Store and receive{" "}
          <br className="hidden lg:block" />1 month of Premium Access to the App
          Free!
        </p>
        <p className="text-grey-light text-xs">
          * Please ensure you use the same Mobile and Email details when
          purchasing as your Clubpass account to ensure your account is credited
          premium access.
        </p>
      </div>
      {after && <div className="w-px h-12 bg-grey-light-2 mx-auto my-6"></div>}
    </>
  );
};

export default SectionHighlight;
