const ContentArea = ({ children, className }) => {
  return (
    <section className={`bg-grey-light-3 min-h-500 animate-fade-in overflow-hidden ${className || ''}`}>
      <div className="content-area max-w-74r mx-auto py-6 px-6 xl:px-0">
        {children}
      </div>
    </section>
  );
};

export default ContentArea;
