import React from 'react'
import { formatMoney } from 'utils';

const TotalSection = ({ title, field = "total", orderTotals, className }) => (
    <div
      className={`flex items-start justify-between text-sm pt-2 opacity-70 ${
        className ? className : ""
      }`}
    >
      {title}:
      <ul className="font-bold">
        {orderTotals.map((order, i) => (
          <li key={order.currency + i} className="text-right uppercase">
            <strong>
              {order.currency} {order.currencySymbol}
              {formatMoney(order[field])}
            </strong>
          </li>
        ))}
      </ul>
    </div>
  );

export default TotalSection