import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateToInt } from "utils";
import useCart from "hooks/useCart";
import { generatePdf } from "utils";

import CommonLayout from "layout/CommonLayout";
import Processed from "components/Order/Processed";
import Button from "components/Field/Button";
import NotFound from "components/NotFound";
import Loading from "components/Checkout/Loading";

// api
import { fetchOrders, getOrderInvoice } from "API";

// store
import { addOrders } from "store/orders/orderSlice";
import { clearUserData } from "store/user/userSlice";

// images
import { ReactComponent as BackIcon } from "images/west_black_24dp.svg";

const PreviousOrders = () => {
  const orders = useSelector((state) => {
    return state.order.value
      .filter((order) => order.status !== "pending")
      .sort((a, b) => dateToInt(b.orderDate) - dateToInt(a.orderDate));
  });
  const [loading, setLoading] = useState(true);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { isSignin } = useCart();
  const downloadInvoice = async (orderId) => {
    setInvoiceLoading(true);
    const res = await getOrderInvoice(orderId, isSignin);
    if (res.base64) {
      const url = generatePdf(res.base64);
      window.open(url, "_blank");
    }
    setInvoiceLoading(false);
  };

  useEffect(() => {
    const getOrders = async () => {
      const { orders } = await fetchOrders();

      if (!orders) {
        dispatch(clearUserData());
        history.replace("/store");
        return;
      }

      dispatch(
        addOrders(orders.map(({ _id, ...order }) => ({ id: _id, ...order })))
      );
      setLoading(false);
    };

    getOrders();
  }, [dispatch, history]);

  return (
    <CommonLayout>
      <section className="grid gap-6 text-center pt-4 pb-16 lg:px-4">
        <div className="md:flex items-center lg:w-900 w-full mx-auto md:pr-44">
          <Link to="/store/">
            <Button
              color="grey-light-2"
              variation="noBorder"
              fontWeight="normal"
              className="text-xs flex space-x-2 items-center rounded w-44"
            >
              <BackIcon className="w-4 h-4" />
              <span>Back to store</span>
            </Button>
          </Link>
          <h1 className="text-2xl font-bold uppercase text-blue-dark flex-grow">
            Your Orders
          </h1>
        </div>

        {orders.length > 0 &&
          orders.map((order) => (
            <Processed
              order={order}
              key={order.id}
              downloadInvoice={downloadInvoice}
            />
          ))}

        {!loading && !orders.length && <NotFound text="No orders yet" />}

        {loading && (
          <Loading>
            <p>Loading orders...</p>
          </Loading>
        )}
      </section>

      {invoiceLoading && (
        <div className="fixed z-100 left-0 top-0 w-full h-full bg-white bg-opacity-80 backdrop-filter grid place-content-center">
          <Loading>
            <p>Downloading Invoice</p>
          </Loading>
        </div>
      )}
    </CommonLayout>
  );
};

export default PreviousOrders;
