// components
import Input from "components/Field/Input";
import Button from "components/Field/Button";

// icon
import { ReactComponent as SearchIcon } from "images/search_black_24dp.svg";

const Form = ({ callback }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const isMobile = window.innerWidth < 400;
  const placeholder = isMobile
    ? "Search our FAQs..."
    : "Start typing to search our FAQs...";

  return (
    <form
      action=""
      method="post"
      className="flex mx-auto md:w-500 w-full items-stretch mb-4"
      onSubmit={handleSubmit}
    >
      <Input
        id="query"
        placeholder={placeholder}
        className="flex-grow relative animate-fade-in"
        callback={callback}
        delay={500}
      />
      <Button className="w-16 p-0 flex-shrink-0" type="button">
        <SearchIcon />
      </Button>
    </form>
  );
};

export default Form;
