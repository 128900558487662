const Divider = ({ children }) => {
  return (
    <div className="flex space-x-5 font-bold text-blue-dark w-full items-center">
      <hr className="flex-grow border-grey-light-4" />
      <strong>{children}</strong>
      <hr className="flex-grow border-grey-light-4" />
    </div>
  );
};

export default Divider;
