import axios from "axios";
import {
  apiConfig,
  setApiConfigCookie,
  setUserDataCookie,
  setGuestIdCookie,
  guestId,
} from "utils/cookies";
import {
  STORE_URL,
  SIGNIN_URL,
  SIGNUP_URL,
  CUSTOMER_CART_URL,
  GUEST_CART_URL,
  CUSTOMER_CHECKOUT_URL,
  ORDERS_URL,
  BANNERS_URL,
  GEO_API,
  FAQS_API,
  UBX_URL,
  ADDRESS_PARSER,
  GUEST_CHECKOUT_URL,
  REFRESH_TOKEN_URL,
} from "config";

export const config = {
  headers: {
    Authorization: apiConfig.Authorization,
  },
  refreshToken: apiConfig.refreshToken,
};

export async function fetchProducts(country, productId) {
  const res = await axios.get(
    `${STORE_URL}/${productId ? `${productId}` : ""}${
      country ? `?country=${country}` : ""
    }`,
    config
  );

  return res.data.data.products;
}

export async function fetchCart(isSignIn = false) {
  try {
    if (isSignIn) await getRefreshToken();

    const apiUrl = isSignIn ? CUSTOMER_CART_URL : GUEST_CART_URL;

    const {
      data: { cart },
    } = await axios.get(apiUrl, config);

    return cart;
  } catch (error) {
    const { data } = error.response;
    const { message = "" } = data || {};
    return {
      error: data || true,
      exists:
        message.toLowerCase().includes("exists") ||
        message.toLowerCase().includes("duplicate"),
    };
  }
}

export async function addToCart(
  items,
  isSignIn = false,
  address = {
    country: "AU",
    state: null,
    city: null,
  }
) {
  try {
    const data = {
      products: items,
      address,
    };

    // extends the life of the guestId everytime the user add/interacts with the cart
    setGuestIdCookie(guestId);

    const apiUrl = isSignIn ? CUSTOMER_CART_URL : GUEST_CART_URL;

    if (isSignIn) await getRefreshToken();

    const res = await axios.put(apiUrl, data, config);

    return res;
  } catch (error) {
    return {
      error: error.response.data || true,
    };
  }
}

export async function signIn(email, phone, password) {
  const data = {
    password,
  };

  if (email) {
    data.email = email;
  }

  if (phone) {
    data.phone = phone;
  }

  try {
    const { data: resData } = await axios.post(SIGNIN_URL, data, config);

    const { token, customer, refresh_token } = resData || {};

    updateTokens(token, refresh_token, customer);

    return customer;
  } catch (error) {
    return {
      error: error.response.data || true,
    };
  }
}

export async function checkUser(user) {
  try {
    const res = await axios.post(SIGNUP_URL, user, config);

    return {
      ...res.data,
    };
  } catch (error) {
    const { data } = error.response;
    const { message = "" } = data || {};
    return {
      error: error.response.data || true,
      exists:
        message.toLowerCase().includes("exists") ||
        message.toLowerCase().includes("duplicate"),
    };
  }
}

export async function registerUser(user) {
  try {
    const res = await axios.post(SIGNUP_URL, user, config);
    return {
      ...res.data,
      signin: true,
    };
  } catch (error) {
    return {
      error: error.response.data || true,
    };
  }
}

export function addOrder(item) {
  // @TODO replace with actual API call to add order details
  return new Promise((resolve) => {
    setTimeout(() => resolve({ data: item, status: "success" }), 500);
  });
}

export async function fetchOrders(orderId = "", isSignIn) {
  try {
    if (isSignIn) await getRefreshToken();
    const { data } = await axios.get(`${ORDERS_URL}/${orderId}`, config);

    return data;
  } catch (error) {
    const { data } = error.response;
    const { message = "" } = data || {};
    return {
      error: data || true,
      exists:
        message.toLowerCase().includes("exists") ||
        message.toLowerCase().includes("duplicate"),
    };
  }
}

export async function trackOrder(orderId, isSignIn) {
  try {
    if (isSignIn) await getRefreshToken();
    const { data } = await axios.get(
      `${ORDERS_URL}/${orderId}/tracking`,
      config
    );

    return data;
  } catch (error) {
    const { data } = error.response;
    const { message = "" } = data || {};
    return {
      error: data || true,
      exists:
        message.toLowerCase().includes("exists") ||
        message.toLowerCase().includes("duplicate"),
    };
  }
}

export async function getOrderInvoice(orderId, isSignIn) {
  try {
    if (isSignIn) await getRefreshToken();
    const { data } = await axios.get(
      `${ORDERS_URL}/${orderId}/invoice`,
      config
    );

    return data;
  } catch (error) {
    const { data } = error.response;
    const { message = "" } = data || {};
    return {
      error: data || true,
      exists:
        message.toLowerCase().includes("exists") ||
        message.toLowerCase().includes("duplicate"),
    };
  }
}

export async function getCountry() {
  return await axios.get(GEO_API);
}

export async function parseAddress(address_string) {
  return await axios({
    method: "post",
    url: ADDRESS_PARSER,
    data: { address_string },
    crossDomain: true,
  });
}

export async function checkout(payload, isSignIn = false) {
  try {
    if (isSignIn) await getRefreshToken();
    const apiUrl = isSignIn ? CUSTOMER_CHECKOUT_URL : GUEST_CHECKOUT_URL;
    const { data } = await axios.post(apiUrl, payload, config);

    return data;
  } catch (error) {
    return {
      error: error.response.data || true,
    };
  }
}

export async function fetchFaqs() {
  try {
    const { data } = await axios.get(`${FAQS_API}`);
    return data;
  } catch (error) {
    return null;
  }
}

export const fetchClubs = async () => {
  try {
    const response = await axios.get(`${UBX_URL}/api/v2/club/data`);
    return response.data.clubs;
  } catch (error) {
    return null;
  }
};

export async function fetchBanners() {
  try {
    const { data } = await axios.get(`${BANNERS_URL}`);

    return data;
  } catch (error) {
    return null;
  }
}

function updateTokens(token, refreshToken, customer) {
  // set token
  config.headers.Authorization = token;
  config.refreshToken = refreshToken;
  setApiConfigCookie({ Authorization: token, refreshToken }); // save token to cookie

  // save customer data to token
  if (customer) setUserDataCookie({ ...customer, signin: true });
}

export async function getRefreshToken() {
  const data = {
    refresh_token: config.refreshToken,
  };
  try {
    const { data: resData } = await axios.post(REFRESH_TOKEN_URL, data, config);

    const { token, refresh_token } = resData || {};
    updateTokens(token, refresh_token);

    return data;
  } catch (error) {
    return null;
  }
}
