// component
import CommonLayout from "layout/CommonLayout";
import VideoEmbed from "components/VideoEmbed";
import AppDownloads from "components/AppDownloads";
import Highlight from "components/Train/Highlight";
import Features from "components/Train/Features";

const TrainOnDemand = () => {
  return (
    <CommonLayout>
      <header className="text-blue-dark text-center py-6 md:py-8 lg:px-6">
        <p className="subheading uppercase font-bold text-lg">introducing //</p>
        <h1 className="uppercase font-bold text-3xl md:text-5xl leading-loose lg:leading-relaxed">
          Train: <strong className="text-bordered">On Demand</strong>
        </h1>
        <p>
          You can now take your training from the club to your home, wherever
          you are and whenever you need it.
        </p>
      </header>
      <VideoEmbed />
      <AppDownloads />
      <Highlight before={true} after={true} />
      <Features />
    </CommonLayout>
  );
};

export default TrainOnDemand;
