import Slider from "react-slick";
import { useRef, useState, useEffect } from "react";

// images
import { ReactComponent as PrevIcon } from "images/expand_less_black_24dp.svg";
import { ReactComponent as NextIcon } from "images/expand_more_black_24dp.svg";

const SlideArrow = ({ onClick, style, className, children }) => (
  <button
    className={`w-8 h-8 rounded-full border-2 border-grey-light-2 duration-300 mx-auto my-2 ${
      className || ""
    }`}
    onClick={onClick}
    style={style}
  >
    {children}
  </button>
);

const PrevArrow = (props) => (
  <SlideArrow {...props}>
    <PrevIcon className="mx-auto text-grey-light-2 hover:text-blue-dark duration-300" />
  </SlideArrow>
);

const NextArrow = (props) => (
  <SlideArrow {...props}>
    <NextIcon className="mx-auto text-grey-light-2 hover:text-blue-dark duration-300" />
  </SlideArrow>
);

const ImageBlock = ({ product }) => {
  const { images, name } = product;
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  });

  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const { nav1, nav2 } = state;

  const sliderNav = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    lazyLoad: true,
  };

  const slider = {
    slidesToShow: 1,
    vertical: false,
    infinite: false,
    arrows: true,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "148px",
          dots: true,
          dotsClass: "product-thumb-dots",
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "24px",
          dots: true,
          dotsClass: "product-thumb-dots",
        },
      },
    ],
  };

  return (
    <div className="image-block flex flex-col md:flex-row w-full">
      <div className="available-images w-14.5 hidden lg:block overflow-hidden">
        {images && images.length > 0 && (
          <Slider
            {...sliderNav}
            asNavFor={nav2}
            ref={(slider) => (slider1.current = slider)}
            className="product-thumb-slider"
          >
            {images.map((image, i) => (
              <div
                className="overflow-hidden rounded product-thumb"
                key={`prod-img-${i}`}
              >
                <img
                  src={image}
                  alt={name}
                  className="object-cover cursor-pointer h-14.5 w-full"
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div className="active-img lg:pl-6 w-full lg:w-524 rounded overflow-hidden">
        {images && images.length > 0 && (
          <Slider
            {...slider}
            asNavFor={nav1}
            ref={(slider) => (slider2.current = slider)}
            className="product-thumb-slider-lg"
          >
            {images.map((image, i) => (
              <img key={`prod-img-fade-${i}`} src={image} alt={name} className="w-full h-full object-cover" />
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default ImageBlock;
