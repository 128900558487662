import { guestId } from "utils/cookies";

const STORE_API = process.env.REACT_APP_STORE_API;
const STORE_FRONT = process.env.REACT_APP_STORE_FRONT;
const STORE_BUCKET = process.env.REACT_APP_STORE_BUCKET;
const UNIVERSAL_API = process.env.REACT_APP_UNIVERSAL_API;
const GOOGLE_APIKEY = process.env.REACT_APP_GOOGLE_APIKEY;
const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
const STRIP_SK = process.env.REACT_APP_STRIP_SK;
const UBX_URL = process.env.REACT_APP_UBX_URL;

const STORE_URL = `${STORE_API}/store-fronts/${STORE_FRONT}/products`;
const SIGNIN_URL = `${STORE_API}/auth/users/login`;
const SIGNUP_URL = `${STORE_API}/auth/users/sign-up`;
const REFRESH_TOKEN_URL = `${STORE_API}/auth/tokens/refresh`;
const CUSTOMER_CART_URL = `${STORE_API}/customer/cart`;
const GUEST_CART_URL = `${STORE_API}/guest/${guestId}/cart`;
const CUSTOMER_CHECKOUT_URL = `${STORE_API}/store-fronts/${STORE_FRONT}/customer/checkout`;
const GUEST_CHECKOUT_URL = `${STORE_API}/store-fronts/${STORE_FRONT}/guest/${guestId}/checkout`;
const ORDERS_URL = `${STORE_API}/orders`;
const BANNERS_URL = `${STORE_API}/banners`;
const GEO_API = `${UNIVERSAL_API}/geo/ip`;
const FAQS_API = `${UNIVERSAL_API}/faqs/store`;
const ADDRESS_PARSER = `${UNIVERSAL_API}/user/address-parser`;

export {
  STORE_API,
  UNIVERSAL_API,
  GOOGLE_APIKEY,
  STRIPE_PK,
  STRIP_SK,
  STORE_FRONT,
  STORE_BUCKET,
  UBX_URL,
  STORE_URL,
  SIGNIN_URL,
  SIGNUP_URL,
  CUSTOMER_CART_URL,
  GUEST_CART_URL,
  CUSTOMER_CHECKOUT_URL,
  GUEST_CHECKOUT_URL,
  ORDERS_URL,
  BANNERS_URL,
  GEO_API,
  FAQS_API,
  ADDRESS_PARSER,
  REFRESH_TOKEN_URL,
};
