import React, { useEffect, useCallback, useRef, useMemo } from "react";
import { debounce, isMatch } from "lodash";
import useCart from "hooks/useCart";

/**
 *
 * This will watch for cart store changes and request to api if the user logged in
 */
const CartWatcher = () => {
  const { items: cartItems, isSignIn, cartUpdate } = useCart();
  const cachedItems = useRef(cartItems);

  const debouncedAddCart = useMemo(
    () =>
      debounce((items) => {
        const isMatched =
          isMatch(cachedItems.current, cartItems) && items.length > 0;
        const noItemsNotSignin = !items.length && !isSignIn;

        // don't proceed when there's no changes or no items & not signin
        if (isMatched || noItemsNotSignin) return;

        cachedItems.current = cartItems;
        cartUpdate(cartItems);
      }, 1000),
    [cartItems, isSignIn, cartUpdate]
  );

  const debounced = useCallback(
    (items) => debouncedAddCart(items),
    [debouncedAddCart]
  );

  useEffect(() => {
    if (cartItems.length > 0) {
      debounced(cartItems);
    }
  }, [cartItems, debounced]);

  return <></>;
};

export default CartWatcher;
