import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUserData } from "store/user/userSlice";
import { clearOrders } from "store/orders/orderSlice";
import { removeSetCookies } from "utils/cookies";
import Signin from "components/Checkout/Signin";
import useCart from "hooks/useCart";

const SigninPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isSignIn, cartUpdate, items: cartItems } = useCart();

  const signInCallback = () => {
    // if there's existing cartItems before signin save it to the user's data
    if (cartItems.length) cartUpdate(cartItems);

    // redirect to order history
    history.push("/store/orders");
  };

  // clear all details when signin page is accessed
  useEffect(() => {
    if (!isSignIn) {
      dispatch(clearUserData());
      dispatch(clearOrders());
      removeSetCookies();
    }
  }, [isSignIn, dispatch]);

  return (
    <div className="py-24 px-8 mx-auto max-w-[480px]">
      <Signin
        signinPage={true}
        signInCallback={signInCallback}
        title="Continue with clubpass account"
      >
        <p className="italic text-grey-light-7 mb-4">
          Ordered with us before, or already have an account? Enter your mobile
          number or email and password that you use to access UBX apps and
          services.
        </p>
      </Signin>
    </div>
  );
};

export default SigninPage;
