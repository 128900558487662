import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFunction } from "lodash";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "./style.autocomplete";
import { GOOGLE_APIKEY } from "config";

// state action
import { setUserData } from "store/user/userSlice";

// api
import { parseAddress } from "API";

// validation
import { isAddressField } from "utils/validations";
import { getAddressValue } from "utils/address";

const Address = ({
  label,
  error = false,
  message,
  callback,
  initValue,
  errorCallback,
  ...props
}) => {
  const dispatch = useDispatch();
  const apiKey = GOOGLE_APIKEY;
  const classes = useStyles();
  const { latitude: lat, longitude: lng } = useSelector((state) => state.user);
  const radius = 0.1;
  const sw = new window.google.maps.LatLng(lat - radius, lng - radius);
  const ne = new window.google.maps.LatLng(lat + radius, lng + radius);
  const bounds = new window.google.maps.LatLngBounds(sw, ne);
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey,
    options: {
      bounds,
      fields: ["address_components", "geometry", "icon", "name"],
    },
  });
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [googleAddressValue, setGoogleAddressValue] = useState(initValue);
  const [hasError, setHasError] = useState(false);
  const [placeId, setPlaceId] = useState("");

  const assignAddressData = (values = {}) => {
    const fields = ["address2", "city", "state", "country", "postalcode"];
    fields.map((field) => {
      dispatch(setUserData({ field, value: values[field] || "" }));
      return field;
    });
  };

  const setAddressFromPlacesService = (placeId) => {
    if (!placeId) return;

    // get the placeId details
    placesService?.getDetails(
      {
        placeId,
      },
      (placeDetails) => {
        const { address_components } = placeDetails;

        // get city, country
        const { short_name: city } = getAddressValue(
          address_components,
          "locality"
        );
        const { short_name: state } = getAddressValue(
          address_components,
          "administrative_area_level_1"
        );
        const { long_name: country } = getAddressValue(
          address_components,
          "country"
        );
        const { long_name: postalcode } = getAddressValue(
          address_components,
          "postal_code"
        );
        assignAddressData({ city, country, state, postalcode });
      }
    );
  };

  const onChange = async (_, value) => {
    const { description, place_id } = value;

    setHasError(false);
    setPlaceId(place_id);
    setGoogleAddressValue(description);
    setAddressFromPlacesService(place_id);

    if (isFunction(callback)) {
      callback(description, place_id);
    }
  };

  const textFieldOnChange = (evt) => {
    const { value } = evt.target;
    getPlacePredictions({
      input: value,
    });
    setGoogleAddressValue(value);

    // clear placeid value if provided value is new
    if (value !== googleAddressValue) {
      setPlaceId("");
    }

    if (isFunction(callback)) {
      callback(value);
    }
  };

  const textFieldOnBlur = async () => {
    // if no placeId it means it was not selected from dropdown.
    // show error and option to toggle manual address field
    if (placeId === "") {
      errorCallback();
      setHasError(true);
    }
    const { data } = await parseAddress(googleAddressValue);

    if (data.item) {
      const itemKeys = Object.keys(data.item);
      for (let key in itemKeys) {
        const { item } = data;
        const field = itemKeys[key];
        const value = item[field];
        if (isAddressField(field)) dispatch(setUserData({ field, value }));
      }
    }
  };

  useEffect(() => {
    if (placePredictions.length) {
      setOptions(placePredictions);
    }
  }, [placePredictions]);

  return (
    <>
      <Autocomplete
        id="address-select"
        noOptionsText={"No address found, please enter address manually"}
        open={open}
        classes={{
          option: classes.option,
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        autoHighlight
        margin="normal"
        options={options}
        loading={isPlacePredictionsLoading}
        onChange={onChange}
        disableClearable
        getOptionLabel={(option) =>
          option.description === null || option.description === undefined
            ? ""
            : option.description
        }
        getOptionSelected={(option, value) => option.description === value}
        value={googleAddressValue}
        defaultValue={googleAddressValue}
        inputValue={googleAddressValue}
        renderOption={(option) => <>{option.description}</>}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              autoComplete="off"
              placeholder={"123 Home Road, NSW, Australia, 17000"}
              style={{ border: 0, boxShadow: "none" }}
              onChange={textFieldOnChange}
              onBlur={textFieldOnBlur}
              value={googleAddressValue}
              className={classes.textField}
              error={hasError || error}
              helperText={hasError || error ? message : ""}
              {...props}
            />
          );
        }}
      />
    </>
  );
};

export default Address;
