const Footer = () => {
  return (
    <div id="footer-iframe-container" className="h-fit-content w-full bg-black">
      <iframe
        id="footer-iframe"
        src={`${process.env.REACT_APP_UBX_URL}/components/footer-export`}
        title="footer"
        scrolling="no"
        frameBorder="0"
        loading="eager"
        className="w-full lg:h-22r h-40r"
      />
    </div>
  );
};

export default Footer;
