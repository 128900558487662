import { Link } from "react-router-dom";
import {ReactComponent as NotFoundIcon } from "images/not-found.svg";

const NotFound = ({ text = "Page Not Found", children }) => {
  return (
    <div className="text-center py-16">
      <p className="text-7xl text-grey-light-2 mb-8">
        <NotFoundIcon width={140} height={140} className="mx-auto" />
      </p>
      {children ? (
        children
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-6 uppercase opacity-70">
            {text}
          </h1>
          <p>
            Go back to our{" "}
            <Link to="/store" className="text-green-main text-base underline">
              store
            </Link>
          </p>
        </>
      )}
    </div>
  );
};

export default NotFound;
