import { Link } from "react-router-dom";

// component
import CommonLayout from "layout/CommonLayout";
import Button from "components/Field/Button";

const Warranty = () => {
  return (
    <CommonLayout>
      <div className="text-center py-12 px-2 xl:px-8 grid gap-y-8 text-grey-light">
        <h1 className="text-3xl font-bold uppercase text-blue-dark">
          Warranty Information
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-y-0 md:gap-x-8  lg:px-8">
          <div className="grid gap-y-4 text-left place-self-start">
            <h2 className="uppercase font-bold text-blue-dark">Equipment</h2>
            <p>
              All equipment sold has a manufacturing warranty that covers the
              structural integrity of the product, and so long as the equipment
              is fit for its original intended purpose (this excludes clearance
              items).
            </p>
            <p>
              The original intended purpose of this equipment is for personal
              and/or at home use only. It is not intended for commercial use.
            </p>
            <p>
              If your product turns out to be faulty or damaged, we will repair,
              replace or refund the item; unless (but not limited to):
            </p>
            <ul className="pl-4 list-disc ml-2">
              <li>
                The product has not been used for its original intended purpose
              </li>
              <li>Normal wear and tear</li>
              <li>Cosmetic damages</li>
              <li>Misuse</li>
              <li>Incorrect installation/assembly</li>
              <li>Improper storage</li>
              <li>Poor maintenance</li>
              <li>Outside the warranty period</li>
              <li>Lack of proof of purchase</li>
            </ul>
            <p>
              Unless otherwise specified, all equipment products have a 3 month
              manufacturing warranty, starting from date of invoice
            </p>
            <p>
              Please be advised that it is the responsibility of the purchaser
              to ensure correct installation and maintenance of the products.
            </p>
          </div>

          <div className="grid gap-y-4 text-left place-self-start">
            <h2 className="uppercase font-bold text-blue-dark">
              Apparel &amp; Accessories
            </h2>
            <p>
              All faulty apparel items are eligible for return for replacement,
              refund or credit note within a 90 day period from receiving your
              purchase.
            </p>
            <p>
              If you believe your item is faulty please{" "}
              <Link
                to={{
                  pathname: `${process.env.REACT_APP_UBX_URL}/support/store`,
                  search: "selectedTopic=Store",
                }}
                target="_blank"
                className="text-green-main hover:underline"
              >
                contact our store and ordering team
              </Link>{" "}
              and include the following information:
            </p>
            <ul className="pl-4 list-disc ml-2">
              <li>Invoice number</li>
              <li>Description of the issue</li>
              <li>Photos or videos showing the issue</li>
            </ul>
            <p>
              We cannot accept liability for an item until it has been confirmed
              as faulty by our team.
            </p>
            <p>
              Please be advised that it is the responsibility of the purchaser
              to ensure correct care of apparel & accessories.
            </p>
          </div>
        </div>
        <div className="grid gap-5 mb-8">
          <hr className=" w-24 max-w-full h-0.5 bg-green-main mx-auto my-4" />
          <p>
            To lodge a Warranty Claim, please first refer to and read our UBX
            Retail Store Product Warranty Policy.
          </p>
          <Link
            to={{
              pathname:
                "https://content.gymsystems.co/pdfs/store/ubx-retil-store-product-warranty-policy.pdf",
            }}
            target="_blank"
          >
            <Button>Store Warranty Policy</Button>
          </Link>
        </div>
      </div>
    </CommonLayout>
  );
};

export default Warranty;
