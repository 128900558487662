// icon
import { ReactComponent as AddIcon } from "images/add_black_24dp.svg";
import { ReactComponent as RemoveIcon } from "images/remove_black_24dp.svg";

const Item = ({ question, className, children, active }) => {
  return (
    <section
      className="grid p-4 w-full rounded-md transition-all duration-200 border border-grey-light-7 text-left animate-fade-in bg-white"
    >
      <header
        className={`flex justify-between space-x-4 xl:items-center cursor-pointer ${
          className || ""
        }`}
      >
        <h6 className="text-lg font-bold text-blue-dark">{question}</h6>
        <button className="duration-200 flex-shrink-0 w-6 h-6 overflow-hidden opacity-50 relative hover:opacity-100">
          <AddIcon
            className={`absolute left-0 duration-200 ${
              active ? "opacity-0 -top-6" : "opacity-100 top-0"
            }`}
          />
          <RemoveIcon
            className={`absolute left-0 duration-200 ${
              active ? "opacity-100 top-0" : "opacity-0 top-6"
            }`}
          />
        </button>
      </header>
      <main
        className={`text-grey-light duration-500 transition-all overflow-hidden grid gap-y-4 ${
          active ? "max-h-screen pt-6 pb-4" : "max-h-0"
        }`}
      >
        {children}
      </main>
    </section>
  );
};

export default Item;
