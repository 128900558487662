import { useRef, useState, useEffect } from "react";

// image
import { ReactComponent as CheckIcon } from "images/done_black_24dp.svg";
const Checkbox = ({
  id,
  name,
  className,
  label,
  type = "checkbox",
  value = "",
  defaultCheck = false,
  callback,
  error,
}) => {
  const el = useRef();
  const [checked, setChecked] = useState(defaultCheck);

  useEffect(() => {
    setChecked(defaultCheck);
  }, [defaultCheck]);

  const handleChange = () => {
    setChecked(!checked);
    if (callback) {
      callback(el.current.value);
    }
  };

  return (
    <label
      htmlFor={`checkbox-${id}`}
      className={`flex items-center space-x-2 cursor-pointer ${
        className || ""
      }`}
    >
      <div
        className={`flex flex-row items-center justify-center w-6 h-6 rounded-full border bg-white ${
          error ? "border-fatal" : "border-grey-light-4"
        }`}
      >
        <CheckIcon className={`w-4 h-4 ${checked ? "visible" : "invisible"}`} />
      </div>
      {label && (
        <span className={checked ? "text-blue-dark" : ""}>{label}</span>
      )}
      <input
        type={type}
        id={`checkbox-${id}`}
        name={`checkbox-${name || id}`}
        className="invisible absolute w-px h-px"
        onChange={handleChange}
        value={value}
        ref={el}
        checked={checked}
      />
    </label>
  );
};

export default Checkbox;
