import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { isFunction } from "lodash";
import { userSignin, clearErrors } from "store/user/userSlice";
import { isEnterKey } from "utils";

// validation
import { validateField } from "utils/validations";

// components
import Phone from "components/Field/Phone";
import Input from "components/Field/Input";
import Button from "components/Field/Button";
import Divider from "components/Divider";
import Loading from "components/Checkout/Loading";
import ForgotPass from "./ForgotPass";

const Signin = ({
  signInCallback,
  guestCheckoutCallback,
  signinPage = false,
  title = "Continue with Clubpass",
  children,
}) => {
  const [useEmail, setUseEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState({ error: false, message: "" });
  const [phone, setPhone] = useState("");
  const [phoneStatus, setPhoneStatus] = useState({ error: false, message: "" });
  const [password, setPassword] = useState("");
  const [passwordStatus, setPasswordStatus] = useState({
    error: false,
    message: "",
  });
  const dispatch = useDispatch();
  const isSignIn = useSelector((state) => state.user.data.signin);
  const countryCode = useSelector((state) => state.user.countryCode);
  const status = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);
  const loading = status === "loading";

  const fieldToggle = () => {
    setUseEmail(!useEmail);
    setEmail("");
    setPhone("");
    dispatch(clearErrors());
  };
  const fieldToggleLabel = useEmail ? "Use Phone" : "Use Email";

  const [open, setOpen] = useState(false);

  const openForgotPass = () => {
    setOpen(true);
  };

  const closeForgotPass = () => {
    setOpen(false);
  };

  const validateFields = () => {
    const { error: emailError, message: emailMsg } = validateField(
      "email",
      email,
      [],
      null,
      null
    );
    setEmailStatus({ error: emailError, message: emailMsg });
    const { error: phoneError, message: phoneMsg } = validateField(
      "phone",
      phone,
      [],
      null,
      null,
      countryCode
    );
    setPhoneStatus({ error: phoneError, message: phoneMsg });
    const { error: passwordError, message: passwordMsg } = validateField(
      "password",
      password,
      [],
      null,
      null
    );
    setPasswordStatus({
      error: passwordError,
      message: passwordMsg,
    });
    return {
      emailError,
      phoneError,
      passwordError,
    };
  };

  const signIn = () => {
    const { emailError, phoneError, passwordError } = validateFields();

    if (useEmail && !emailError && !passwordError) {
      dispatch(userSignin({ email, password }));
      return;
    }

    if (!useEmail && !phoneError && !passwordError) {
      dispatch(userSignin({ phone, password }));
    }
  };

  const guestCheckout = () => {
    if (guestCheckoutCallback) guestCheckoutCallback();
  };

  const onKeyPress = (e) => {
    if (loading) return;
    if (isEnterKey(e.key)) {
      signIn();
    }
  };

  useEffect(() => {
    if (isSignIn && isFunction(signInCallback)) signInCallback();
  }, [isSignIn, signInCallback]);

  return (
    <div className="w-full mx-auto lg:w-480 grid gap-6 pb-16">
      <h4 className="text-blue-dark uppercase text-xl xl:text-2xl text-center font-bold">
        {title}
      </h4>
      {children ? (
        children
      ) : (
        <p className="italic text-grey-light-7 mb-4">
          Already a member? Enter your phone and password that you have
          previously used to access TRAIN: On Demand, The Training Camp, or if
          you're new here, check out as a Guest.
        </p>
      )}

      <div
        className={`duration-300 grid gap-6 ${
          loading ? "pointer-events-none opacity-20" : ""
        }`}
      >
        {useEmail ? (
          <Input
            id="email"
            label="Email Address"
            callback={(value) => {
              setEmail(value);
              const { error, message } = validateField(
                "email",
                value,
                [],
                null,
                null
              );
              setEmailStatus({ error, message });
            }}
            focus={true}
            error={emailStatus.error}
            message={emailStatus.message}
            autoComplete="username"
            onKeyPress={onKeyPress}
          />
        ) : (
          <Phone
            callback={(value, countryCode) => {
              setPhone(value);
              const { error, message } = validateField(
                "phone",
                value,
                [],
                null,
                null,
                countryCode
              );
              setPhoneStatus({ error, message });
            }}
            autoComplete="username"
            error={phoneStatus.error}
            message={phoneStatus.message}
            onKeyPress={onKeyPress}
          />
        )}
        <Input
          id="password"
          type="password"
          label="Password"
          placeholder="Enter your password"
          callback={(value) => {
            setPassword(value);
            const { error, message } = validateField(
              "password",
              value,
              [],
              null,
              null
            );
            setPasswordStatus({ error, message });
          }}
          forgotPass="Forgot your password?"
          forgotPassfn={() => openForgotPass()}
          error={passwordStatus.error}
          message={passwordStatus.message}
          autoComplete="current-password"
          onKeyPress={onKeyPress}
        />
      </div>

      {!loading && error && error.message && (
        <Alert severity="error">{error.message}</Alert>
      )}

      {loading && (
        <Loading>
          <p>Signing in....</p>
        </Loading>
      )}

      <Button
        color="green-main"
        className="flex justify-center items-center"
        onClick={signIn}
        disabled={loading}
      >
        {!signinPage ? "Continue" : "Sign-in"}
      </Button>
      <Button
        variation="outline"
        color="grey-light"
        className="flex justify-center items-center disabled:text-white"
        callback={fieldToggle}
        disabled={loading}
      >
        {fieldToggleLabel}
      </Button>

      {!signinPage && (
        <>
          <Divider>or</Divider>
          <Button
            variation="outline"
            color="grey-light"
            className="flex justify-center items-center"
            callback={guestCheckout}
          >
            Checkout as guest
          </Button>
        </>
      )}

      {/* forget password */}
      <ForgotPass open={open} closeForgotPass={closeForgotPass} />
    </div>
  );
};

export default Signin;
