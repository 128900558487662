export const totalPerCurrency = (arr) => {
  if (!arr && arr.length < 1) return [];

  const itemsPerPrice = arr
    .map(({ quantity, postage, tax: currentTax, price, currencySymbol, currency, total, totalItemPrice}) => {
      const postageTotal = postage * quantity
      const {total: taxValue = 0} = currentTax || {}
      const taxTotal = taxValue * quantity
      const subtotal = price * quantity
      return {
        currency,
        currencySymbol,
        subtotal: subtotal,
        subtotalNoPostage: subtotal + taxTotal,
        total: total || totalItemPrice,
        postageTotal,
        taxTotal
      }
    })
    .sort((a, b) => {
      return a.currency > b.currency ? -1 : 1;
    });

  const result = itemsPerPrice.reduce((prev, current) => {
    let sameCurrency = false;
    if (prev) {
      if (prev.length > 0) {
        if (prev[prev.length - 1].currency === current.currency) {
          prev[prev.length - 1].subtotal += current.subtotal;
          prev[prev.length - 1].total += current.total;
          prev[prev.length - 1].postageTotal += current.postageTotal;
          prev[prev.length - 1].taxTotal += current.taxTotal;
          prev[prev.length - 1].subtotalNoPostage += current.subtotalNoPostage;
          sameCurrency = true;
        }
      }
      if (!sameCurrency) {
        prev.push({ ...current });
      }
    }
    return prev;
  }, []);

  return result;
};

export const hasItems = (order) => order && order.items?.length > 0;

export const getMinMaxDate = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const maxYear = currentYear - 18;
  const minYear = currentYear - 120;
  return {
    min: new Date(minYear, currentMonth, currentDay),
    max: new Date(maxYear, currentMonth, currentDay),
  };
};

export const getReadableDate = (dateStr) => {
  if (!dateStr) return;

  const date = new Date(dateStr);
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "medium",
    hourCycle: "h24",
  }).format(date);
};

export const dateToInt = (dateString) => new Date(dateString).getTime();

export const htmlToString = (str) => str?.replace(/<[^>]*>/g, "");

export const isEnterKey = (key) => key.toLowerCase() === "enter";

// this function will help to generate the pdf
// https://stackoverflow.com/a/62392022
const dataURItoBlob = (dataURI) => {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: "application/pdf" });
  return blob;
};
export const generatePdf = (base64Data) => {
  const blob = dataURItoBlob(base64Data);
  const url = URL.createObjectURL(blob);

  return url;
};

// function to format money
export const formatMoney = (amount, decimalCount = 2, thousands = ",") => {
  try {
    const absDecimalCount = Math.abs(decimalCount);
    const useDecimalCount = isNaN(absDecimalCount) ? 2 : absDecimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(useDecimalCount)))
      .toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    const decimalVal = Math.abs(amount - i).toFixed(useDecimalCount).slice(2)
    const decimalDisplay = parseInt(decimalVal) > 0 ? `.${decimalVal}` : ''

    return (
      negativeSign +
      (j ? i.slice(0, j) + thousands : "") +
      i.slice(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + decimalDisplay
    );
  } catch (e) {
    console.log(e);
  }
}
