import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  cartGet,
  toggleActive,
  toggleSelectAll,
  removeSelected,
  clearCart,
  sanitizeCart,
  replaceCart,
  setCartReplacedStatus,
  updateCartStatus
} from "store/cart/cartSlice";
import { productsGet } from "store/products/productsSlice.js";
import { clearUserData } from "store/user/userSlice";
import { removeSetCookies, setCartCookies } from "utils/cookies";
import { totalPerCurrency } from "utils";
import { itemsTransformer } from "utils/cart";
import { addToCart } from "API";

const useCart = () => {
  const items = useSelector((state) => state.cart.value);
  const cartHasItems = items && items.length;
  const itemsSelected = cartHasItems
    ? items.filter((item) => item.selected)
    : null;
  const itemsNotSelected = cartHasItems
    ? items.filter((item) => !item.selected)
    : null;
  const {active: isActive, replaced: cartReplaced} = useSelector((state) => state.cart);
  const {
    signin: isSignIn,
    state: stateVal,
    city: cityVal,
  } = useSelector((state) => state.user.data);
  const countryCode = useSelector((state) => state.user.countryCode);
  const itemCount = cartHasItems
    ? items.reduce((a, b) => {
        const qtyB = b.quantity;
        if (a && qtyB) return a + qtyB;
        return qtyB;
      }, 0)
    : 0;
  const dispatch = useDispatch();
  const hasItems = itemsSelected && itemsSelected.length > 0;
  const orderSubtotals = hasItems ? totalPerCurrency(itemsSelected) : [];

  const toggleCart = () => {
    dispatch(toggleActive(!isActive));
  };

  const toggleItems = () => {
    dispatch(toggleSelectAll());
  };

  const removeSelectedItems = () => {
    dispatch(removeSelected());
    // empty the cart in the api
    if (itemsSelected.length === items.length) return cartUpdate([]);
    // remove only selected items
    const remainingItems = itemsTransformer(itemsNotSelected);
    cartUpdate(remainingItems);
  };

  const emptyCart = useCallback(() => {
    dispatch(clearCart());
    cartUpdate([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const cartUpdate = async (cartItems, address = {}) => {

    // use details from address to recalculate postage / shipment
    const {
      country = countryCode?.toUpperCase(),
      city = cityVal,
      state = stateVal,
    }  = address

    const items = itemsTransformer(cartItems);

    // save cart to cookies
    setCartCookies(cartItems);

    dispatch(updateCartStatus("loading"));

    // call cart api
    const res = await addToCart(items, isSignIn, {
      country,
      city,
      state,
    });

    dispatch(updateCartStatus("idle"));

    // signout user if token is expired
    if (res.error) {
      dispatch(clearUserData());
      removeSetCookies();
      return;
    }

    // fetch the products everytime cart is updated
    if(countryCode) {
      dispatch(productsGet({ countryCode }));
    }

    // if cart was replaced with the new one, don't update the cart
    if(cartReplaced) {
      dispatch(setCartReplacedStatus(false));
      return;
    }

    // use update cart response to update cart store value
    dispatch(replaceCart(res.data?.updatedCart));
  };

  const cleanCart = useCallback(
    (products) => dispatch(sanitizeCart(products)),
    [dispatch]
  );

  const getCartData = useCallback(
    () => dispatch(cartGet(isSignIn)),
    [isSignIn, dispatch]
  );

  return {
    items,
    cartIsActive: isActive,
    itemCount,
    hasItems,
    orderSubtotals,
    isSignIn,
    toggleCart,
    removeSelectedItems,
    toggleItems,
    itemsSelected,
    emptyCart,
    getCartData,
    cartUpdate,
    cleanCart,
  };
};

export default useCart;
