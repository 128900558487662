import React from "react";

const Step = ({
  isFirst = false,
  isLast = false,
  isActive = false,
  name,
  children,
}) => {
  const statusColor = isActive ? "green-main" : "grey-light-2";
  const spacerClass = `h-0.5 w-4 lg:w-12 bg-${statusColor} mt-6 md:mt-8`;

  return (
    <>
      {!isFirst && <div className={spacerClass}></div>}
      <div className={`w-12 md:w-16 grid items-center text-${statusColor}`}>
        <div
          className={`icon-container rounded-full flex items-center justify-center mb-2 w-12 h-12 md:w-16 md:h-16 border-2 border-${statusColor}`}
        >
          {children}
        </div>
        <strong className="uppercase block text-xs whitespace-nowrap">
          {name}
        </strong>
      </div>
      {!isLast && <div className={spacerClass}></div>}
    </>
  );
};

export default Step;
